import React, { useEffect, useState } from "react";

const Theme = ({ data, updateData }) => {
  const [formData, setFormData] = useState({
    description: data.description ? data.description : "",
    language: data.language ? data.language : "",
    acceptedCategories: data.acceptedCategories?.length
      ? data.acceptedCategories
      : [],
    blockedWebsites: data.blockedWebsites ? data.blockedWebsites : "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      if (checked) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          acceptedCategories: [...prevFormData.acceptedCategories, value],
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          acceptedCategories: prevFormData.acceptedCategories.filter(
            (category) => category !== value
          ),
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    updateData(formData); // Trigger update in parent component
  }, [formData]);

  return (
    <div style={{ width: "160%" }}>
      <div className="form-group w-100">
        <label>Short Description *</label>
        <textarea
          id="description"
          style={{ resize: "none" }}
          className="w-100"
          placeholder="Enter the description of your website (maximum 600 characters)"
          type="text"
          name="description"
          value={formData.description}
          onChange={handleChange}
          required
          rows={4}
        />
      </div>
      <div className="form-group">
        <label htmlFor="language">Tags *</label>
        <input
          id="language"
          placeholder="Enter your tags"
          type="text"
          name="language"
          value={formData.language}
          onChange={handleChange}
          required
        />
        <p style={{ fontSize: ".8em" }} className="text-end">
          MAXIMUM 10 NUMBERS OF TAGS (0/10)
        </p>
      </div>
      <div className="checkbox-group form-group">
        <p>I accept keywords and links from business in:</p>
        <div
          style={{ color: "#8f8f8f", gap: "1em" }}
          className="d-sm-flex mb-3"
        >
          <span>
            <input
              style={{ width: "20px" }}
              type="checkbox"
              name="acceptedCategories"
              value="Gambling"
              checked={formData.acceptedCategories.includes("Gambling")}
              onChange={handleChange}
            />
            Gambling
            <br />
            <input
              style={{ width: "20px" }}
              type="checkbox"
              name="acceptedCategories"
              value="CBD"
              checked={formData.acceptedCategories.includes("CBD")}
              onChange={handleChange}
            />
            CBD
            <br />
            <input
              style={{ width: "20px" }}
              type="checkbox"
              name="acceptedCategories"
              value="Cryptocurrencies"
              checked={formData.acceptedCategories.includes("Cryptocurrencies")}
              onChange={handleChange}
            />
            Cryptocurrencies
            <br />
          </span>
          <span>
            <input
              style={{ width: "20px" }}
              type="checkbox"
              name="acceptedCategories"
              value="Erotica"
              checked={formData.acceptedCategories.includes("Erotica")}
              onChange={handleChange}
            />
            Erotica
            <br />
            <input
              style={{ width: "20px" }}
              type="checkbox"
              name="acceptedCategories"
              value="Dietary supplements and pharmacy"
              checked={formData.acceptedCategories.includes(
                "Dietary supplements and pharmacy"
              )}
              onChange={handleChange}
            />
            Dietary supplements and pharmacy
            <br />
            <input
              style={{ width: "20px" }}
              type="checkbox"
              name="acceptedCategories"
              value="Loans"
              checked={formData.acceptedCategories.includes("Loans")}
              onChange={handleChange}
            />
            Loans
            <br />
          </span>
        </div>
      </div>
      <div className="form-group w-100">
        <label htmlFor="blockedWebsites">List of blocked websites*</label>
        <textarea
          id="blockedWebsites"
          style={{ resize: "none" }}
          className="w-100"
          placeholder="Enter the list comma separated"
          type="text"
          name="blockedWebsites"
          value={formData.blockedWebsites}
          onChange={handleChange}
          required
          rows={4}
        />
      </div>
    </div>
  );
};

export default Theme;
