import React, { useEffect, useState } from 'react';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import {
    Table,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal';
import { BASE_API_URL } from '../src/config/constants';
import './AdvertiserStep1.css';
import PageLayout from './components/PageLayout';

const AdvertiserStep1 = () => {
    const [data, setData] = useState([]);
    const [totalPrices, setTotalPrices] = useState({});
    const [filters, setFilters] = useState({
        domainName: '',
        theme: '',
        country: '',
        typeOfPortal: '',
        region: '',
        minUniqueUsers: '',
        dofollowLinks: false,
        typeOfLinks: '',
        numberOfLinks: '',
        priceFrom: '',
        priceTo: '',
        attractivePrice: false,
        domainAuthority: '',
        portalQuality: '',
        favorites: false,
    });
    const [uniqueCountries, setUniqueCountries] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const fetchData = async (filters) => {
        try {
            const response = await fetch(`${BASE_API_URL}api/UserDomains`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(filters)
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setData(jsonData);

            // Extract unique countries from data
            const countries = [...new Set(jsonData.map(entry => entry.country))];
            setUniqueCountries(countries);

            // Fetch total prices for all entries
            jsonData.forEach(entry => fetchTotalPrice(entry));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchTotalPrice = async (entry) => {
        const url = `${BASE_API_URL}api/pricecalculator/calculate?domainAuthority=${entry.domainAuthority}&exclusivity=false&linkType=NormalFollow&price=${entry.price}`;
        try {
            const response = await fetch(url);
            const result = await response.json();
            setTotalPrices(prev => ({ ...prev, [entry.domain]: result.basePrice }));
        } catch (error) {
            console.error('Error fetching total price:', error);
        }
    };

    useEffect(() => {
        fetchData(filters);
    }, [filters]);

    const [modalVisible, setModalVisible] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFilters({ ...filters, [name]: checked });
    };

    const handleChooseClick = (entry) => {
        setSelectedEntry(entry);
        setModalVisible(true);
    };

    const handleSearch = () => {
        fetchData(filters);
        setCurrentPage(1);
    }

    const ArrowSpan = () => {
        return (
            <span className='row h-25'>
                <FontAwesomeIcon icon={faCaretUp} size='m' style={{ color: "black" }} />
                <FontAwesomeIcon icon={faCaretDown} size='m' style={{ color: "black" }} />
            </span>
        );
    };

    const handlePageChange = (pageNumber) => {
        console.log(`Changing page to: ${pageNumber}`);
        setCurrentPage(pageNumber);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    console.log(`Current Page: ${currentPage}, Items per Page: ${itemsPerPage}`);
    console.log(`Index of First Item: ${indexOfFirstItem}, Index of Last Item: ${indexOfLastItem}`);
    console.log(`Current Items: ${currentItems.length}`);

    const priceStyle = {
        fontSize: '1.25rem'
    };

    return (
        <>
                <PageLayout>
            
            <div className="container5 overflow-hidden">
                <div className="mt-0 ms-4 subCont5 pt-0">
                    <div className="header">
                        <h5>Advertiser</h5>
                    </div>
                    <p>
                        (you will be able to buy links in already existing native articles - the most valuable ones)
                    </p>
                    <h3 className='mt-4'>Choose a publication</h3>
                    <div className='inputDiv'>
                        <p>Basic filters</p>
                        <h5>Basic filters</h5>
                        <div className="form-group2 d-flex flex-wrap">
                            <input
                                name='domainAuthority'
                                placeholder='Domain Authority'
                                type="text"
                                value={filters.domainAuthority}
                                onChange={handleInputChange}
                            />
                            <input
                                name='domainName'
                                placeholder='Domain website name, tag'
                                type="text"
                                value={filters.domainName}
                                onChange={handleInputChange}
                            />
                            <select
                                name='country'
                                value={filters.country}
                                onChange={handleInputChange}
                            >
                                <option value="">Country</option>
                                {uniqueCountries.map((country, index) => (
                                    <option key={index} value={country}>{country}</option>
                                ))}
                            </select>
                            {/* <select
                                name='typeOfPortal'
                                value={filters.typeOfPortal}
                                onChange={handleInputChange}
                            >
                                <option selected>Type of portal</option>
                                <option value="Option2">Option2</option>
                            </select> */}
                            {/* <select
                                name='region'
                                value={filters.region}
                                onChange={handleInputChange}
                            >
                                <option selected>Region</option>
                                <option value="Option2">Option2</option>
                            </select> */}
                            <input
                                name='minUniqueUsers'
                                placeholder='Min. unique users'
                                type="text"
                                value={filters.minUniqueUsers}
                                onChange={handleInputChange}
                            />
                            {/* <span className='d-flex justify-content-between'>
                                <p>Dofollow links</p>
                                <input
                                    name='dofollowLinks'
                                    className='h-100'
                                    type="checkbox"
                                    checked={filters.dofollowLinks}
                                    onChange={handleCheckboxChange}
                                />
                            </span> */}
                            {/* <select
                                name='typeOfLinks'
                                value={filters.typeOfLinks}
                                onChange={handleInputChange}
                            >
                                <option selected>Type of Links</option>
                                <option value="Option2">Option2</option>
                            </select> */}
                            {/* <select
                                name='numberOfLinks'
                                value={filters.numberOfLinks}
                                onChange={handleInputChange}
                            >
                                <option selected>Number of links in the co..</option>
                                <option value="Option2">Option2</option>
                            </select> */}
                            <span className='d-flex px-0 py-0 justify-content-between gap-3 border-0'>
                                <input
                                    name='priceFrom'
                                    className='w-50 border rounded px-2'
                                    placeholder='Price from'
                                    type="text"
                                    value={filters.priceFrom}
                                    onChange={handleInputChange}
                                />
                                <input
                                    name='priceTo'
                                    className='w-50 border rounded px-2'
                                    placeholder='Price to'
                                    type="text"
                                    value={filters.priceTo}
                                    onChange={handleInputChange}
                                />
                            </span>
                            {/* <span className='d-flex justify-content-between'>
                                <p>Attractive price</p>
                                <input
                                    name='attractivePrice'
                                    className='h-100'
                                    type="checkbox"
                                    checked={filters.attractivePrice}
                                    onChange={handleCheckboxChange}
                                />
                            </span> */}
                            
                            {/* <select
                                name='portalQuality'
                                value={filters.portalQuality}
                                onChange={handleInputChange}
                            >
                                <option selected>Portal quality</option>
                                <option value="Option2">1</option>
                                <option value="Option3">2</option>
                                <option value="Option4">3</option>
                                <option value="Option5">4</option>
                                <option value="Option6">5</option>
                                <option value="Option7">6</option>
                                <option value="Option8">7</option>
                                <option value="Option9">8</option>
                                <option value="Option10">9</option>
                                <option value="Option11">10</option>
                            </select> */}
                            {/* <span className='d-flex justify-content-between'>
                                <p>Favorites</p>
                                <input
                                    name='favorites'
                                    className='h-100'
                                    type="checkbox"
                                    checked={filters.favorites}
                                    onChange={handleCheckboxChange}
                                />
                            </span> */}
                        </div>
                    </div>
                    <div className="form-actions1 mb-3">
                        <button onClick={() => handleSearch()} type="button" className="btn btn-primary">Search</button>
                        {/* <button type="button" className="btn btn-primary">Advanced Filters</button> */}
                        {/* <button type="button" className="btn btn-primary">Saved Searches</button> */}
                    </div>
                    <div className='adverTable'>
                        <div className="table-responsive overflow-visible table-wrapper">
                            <Table>
                                <thead style={{ verticalAlign: 'middle' }}>
                                    <tr>
                                        <th scope="col">Portal
                                            <span className='d-flex justify-content-between'>
                                                <small style={{ fontWeight: 'lighter' }}>
                                                    Type <br />
                                                    Country: Region<br />
                                                    Theme
                                                </small>
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                        <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                Unique Users
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                        <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                SemRush
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                        {/* <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                Trust Flow
                                                <ArrowSpan />
                                            </span>
                                        </th> */}
                                        {/* <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                Average <br />
                                                URL Rating
                                                <ArrowSpan />
                                            </span>
                                        </th> */}
                                        <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                Dofollow
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                        <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                Traffic<br />
                                                Tracking
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                        <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                Content<br />
                                                Quality
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                        <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                Technical<br />
                                                Quality
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                        <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                Domain<br />
                                                Authority
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                        <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                Price<br />
                                                /Month
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.map((entry, index) => (
                                        <tr key={index}>
                                            <td className='border-right align-middle'>
                                                <h5>{entry.domain}</h5>
                                                <small>
                                                    {entry.websiteType}<br/>
                                                </small>
                                                <small>
                                                    {entry.country}
                                                </small>
                                                <small>
                                                    {entry.theme}
                                                </small>
                                            </td>
                                            <td className='border align-middle text-center'>
                                                <small>{entry.uniqueUsers}</small>
                                            </td>
                                            <td className='border align-middle text-center'>
                                                <small>{entry.semRush}</small>
                                            </td>
                                            {/* <td className='border align-middle text-center'>
                                                <small>{entry.trustFlow}</small>
                                            </td> */}
                                            {/* <td className='border align-middle text-center'>
                                                <small>{entry.urlRating}</small>
                                            </td> */}
                                            <td className='border align-middle text-center'>
                                                {/* TODO: color */}
                                                <FontAwesomeIcon icon={entry.dofollow ? faCircleCheck : faCircleXmark} size='l' style={{ color: entry.dofollow ? "red" : "#21c442", marginRight: '3px' }} />
                                            </td>
                                            <td className='border align-middle text-center'>
                                                <FontAwesomeIcon icon={entry.trafficTracking ? faCircleCheck : faCircleXmark} size='l' style={{ color: entry.trafficTracking ? "#21c442" : "red", marginRight: '3px' }} />
                                            </td>
                                            <td className='border align-middle text-center'>
                                                <small>{entry.contentQuality}</small>
                                            </td>
                                            <td className='border align-middle text-center'>
                                                <small>{entry.technicalQuality}</small>
                                            </td>
                                            <td className='border align-middle text-center'>
                                                <small>{entry.domainAuthority}</small>
                                            </td>
                                            <td className='border-left align-middle text-center'>
                                                <small style={priceStyle} className='me-2'>
                                                    {totalPrices[entry.domain] >= 0
                                                        ? `${totalPrices[entry.domain]}$` 
                                                        : 'Loading...'}/month
                                                </small>
                                                <button className='mt-0 btn btn-primary btn-sm' onClick={() => handleChooseClick(entry)}>Add</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        {data.length > itemsPerPage && (
                            <Pagination aria-label="Page navigation">
                                <PaginationItem disabled={currentPage === 1}>
                                    <PaginationLink onClick={() => handlePageChange(currentPage - 1)} previous />
                                </PaginationItem>
                                {[...Array(Math.ceil(data.length / itemsPerPage))].map((_, index) => (
                                    <PaginationItem key={index} active={index + 1 === currentPage}>
                                        <PaginationLink onClick={() => handlePageChange(index + 1)}>
                                            {index + 1}
                                        </PaginationLink>
                                    </PaginationItem>
                                ))}
                                <PaginationItem disabled={currentPage === Math.ceil(data.length / itemsPerPage)}>
                                    <PaginationLink onClick={() => handlePageChange(currentPage + 1)} next />
                                </PaginationItem>
                            </Pagination>
                        )}
                    </div>
                    <div className="text-end mt-2" style={{ paddingRight: '98px', fontSize: '1.50rem' }}>
                        <small><em>*prices without VAT</em></small>
                    </div>
                </div>
                {modalVisible && <Modal entry={selectedEntry} onClose={() => setModalVisible(false)} />}
            </div>
            </PageLayout>
        </>
    );
}

export default AdvertiserStep1;
