import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import { BASE_API_URL } from '../src/config/constants';
import { useAuth } from './authService';
import UserDetailsModal from '../src/UserDetailsModal';

const AdminPanel = () => {
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
    email: '',
    status: '',
    userType: '',
    creationDate: '',
    lastModifiedDate: '',
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { user, token } = useAuth();

  useEffect(() => {
    if (!user || user.userType !== 'sysadmin') {
      navigate('/');
      return;
    }

    fetchUsers();
  }, [user, navigate, token]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}api/User/AdminPanel`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleStatusChange = async (userId, newStatus) => {
    try {
      const userToUpdate = users.find(user => user.userId === userId);
      await axios.put(`${BASE_API_URL}api/User/${userId}`, {
        ...userToUpdate,
        isActive: newStatus === 'active',
        lastModifiedDate: new Date()
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchUsers();
    } catch (error) {
      console.error('Error updating user status:', error);
    }
  };

  const handleUserTypeChange = async (userId, newUserType) => {
    try {
      const userToUpdate = users.find(user => user.userId === userId);
      await axios.put(`${BASE_API_URL}api/User/${userId}`, {
        ...userToUpdate,
        userType: newUserType,
        lastModifiedDate: new Date()
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchUsers();
    } catch (error) {
      console.error('Error updating user type:', error);
    }
  };

  const handleHideUser = async (userId) => {
    try {
      await axios.post(`${BASE_API_URL}api/User/${userId}/hide`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchUsers();
    } catch (error) {
      console.error('Error hiding user:', error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const clearFilters = () => {
    setFilters({
      email: '',
      status: '',
      userType: '',
      creationDate: '',
      lastModifiedDate: '',
    });
  };

  const filteredUsers = users.filter(user => {
    return (
      user.email.toLowerCase().includes(filters.email.toLowerCase()) &&
      (filters.status === '' || user.isActive.toString() === filters.status) &&
      (filters.userType === '' || user.userType === filters.userType) &&
      (filters.creationDate === '' || new Date(user.creationDate).toDateString() === new Date(filters.creationDate).toDateString()) &&
      (filters.lastModifiedDate === '' || new Date(user.lastModifiedDate).toDateString() === new Date(filters.lastModifiedDate).toDateString())
    );
  });

  const openUserDetails = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  if (user?.userType !== 'sysadmin') {
    return <div>Access Denied</div>;
  }

  return (
    <>
      <Navbar />
      <div className="container px-5">
        <Sidebar />
        <div className="main">
          <h5>Admin Approval Page</h5>
          <div style={{ textAlign: "center", marginBottom: "1rem" }}>
    <Link 
        to="/hidden-users" 
        className="btn btn-secondary" 
        style={{ display: "inline-block", width: "auto", padding: "0.5rem 1rem" }}
    >
        View Hidden Users
    </Link>
</div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control mb-2"
              placeholder="Filter by Email"
              name="email"
              value={filters.email}
              onChange={handleFilterChange}
            />
            <select
              className="form-select mb-2"
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
            >
              <option value="">All Statuses</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
            <select
              className="form-select mb-2"
              name="userType"
              value={filters.userType}
              onChange={handleFilterChange}
            >
              <option value="">All User Types</option>
              <option value="publisher">Publisher</option>
              <option value="advertiser">Advertiser</option>
              <option value="sysadmin">Sysadmin</option>
            </select>
            <label>Exact creation date:</label>
            <input
              type="date"
              className="form-control mb-2"
              name="creationDate"
              value={filters.creationDate}
              onChange={handleFilterChange}
            />
            <label>Exact modification date:</label>
            <input
              type="date"
              className="form-control mb-2"
              name="lastModifiedDate"
              value={filters.lastModifiedDate}
              onChange={handleFilterChange}
            />
            <button className="btn btn-secondary" onClick={clearFilters}>Clear Filters</button>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Status</th>
                <th>User Type</th>
                <th>Creation Date</th>
                <th>Last Modified Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map(user => (
                <tr key={user.userId}>
                  <td>
                    <a href="#" onClick={() => openUserDetails(user)}>{user.email}</a>
                  </td>
                  <td>{user.isActive ? 'Active' : 'Inactive'}</td>
                  <td>{user.userType}</td>
                  <td>{new Date(user.creationDate).toLocaleString()}</td>
                  <td>{new Date(user.lastModifiedDate).toLocaleString()}</td>
                  <td>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`status_${user.userId}`}
                        id={`active_${user.userId}`}
                        checked={user.isActive}
                        onChange={() => handleStatusChange(user.userId, 'active')}
                      />
                      <label className="form-check-label" htmlFor={`active_${user.userId}`}>
                        Active
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`status_${user.userId}`}
                        id={`inactive_${user.userId}`}
                        checked={!user.isActive}
                        onChange={() => handleStatusChange(user.userId, 'inactive')}
                      />
                      <label className="form-check-label" htmlFor={`inactive_${user.userId}`}>
                        Inactive
                      </label>
                    </div>
                    <select
                      className="form-select form-select-sm ms-2"
                      value={user.userType}
                      onChange={(e) => handleUserTypeChange(user.userId, e.target.value)}
                    >
                      <option value="publisher">Publisher</option>
                      <option value="advertiser">Advertiser</option>
                      <option value="sysadmin">Sysadmin</option>
                    </select>
                    <button
                      className="btn btn-danger btn-sm ms-2"
                      onClick={() => handleHideUser(user.userId)}
                    >
                      Hide
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showModal && (
        <UserDetailsModal
          user={selectedUser}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default AdminPanel;