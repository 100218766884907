// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-responsive {
    margin-top: 20px;
  }
  
  .table {
    background-color: #fff;
    border: 1px solid #ddd;
  }
  
  .thead-dark {
    background-color: #343a40;
    color: white;
  }
  
  .p-911 {
    text-align: center;
    color: #f00;
    font-weight: bold;
  }
  

  .table-responsive {
    overflow-x: visible;
  }

  
  thead th {
    min-width: 100px; /* Ensure minimum width for each header cell */
  }`, "",{"version":3,"sources":["webpack://./src/AdminLinksReports.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,iBAAiB;EACnB;;;EAGA;IACE,mBAAmB;EACrB;;;EAGA;IACE,gBAAgB,EAAE,8CAA8C;EAClE","sourcesContent":[".table-responsive {\n    margin-top: 20px;\n  }\n  \n  .table {\n    background-color: #fff;\n    border: 1px solid #ddd;\n  }\n  \n  .thead-dark {\n    background-color: #343a40;\n    color: white;\n  }\n  \n  .p-911 {\n    text-align: center;\n    color: #f00;\n    font-weight: bold;\n  }\n  \n\n  .table-responsive {\n    overflow-x: visible;\n  }\n\n  \n  thead th {\n    min-width: 100px; /* Ensure minimum width for each header cell */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
