import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';
import { useNavigate } from 'react-router-dom';
import Checkout from './Checkout';
import PageLayout from './components/PageLayout';
import './YourOrders.css';

const YourOrders = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [showCheckout, setShowCheckout] = useState(false);
  const navigate = useNavigate();

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}api/Order/get`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setOrders(response.data);
    } catch (err) {
      setError('Failed to fetch order data');
      console.error('Error fetching orders:', err);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (orders.length === 0) {
    return <div>Loading...</div>;
  }

  const calculateTotal = (wordToLinks) => {
    if (!wordToLinks) return 0;
    return wordToLinks.reduce((total, item) => total + item.monthlyValue, 0);
  };

  const handleDelete = async (orderId, wtlId) => {
    try {
      await axios.delete(`${BASE_API_URL}api/WordToLink/${wtlId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      // After successful deletion, fetch the updated orders
      fetchOrders();
    } catch (err) {
      setError('Failed to delete item');
      console.error('Error deleting item:', err);
    }
  };

  const handleOrderAndPay = () => {
    setShowCheckout(true);
  };

  if (showCheckout) {
    // Assuming you need to pass the amount for the checkout
    const totalAmount = orders.flatMap(order => order.wordToLinks).reduce((total, item) => total + item.monthlyValue, 0);
    return (
      <Checkout 
        amount={totalAmount}
        products={orders.flatMap(order => order.wordToLinks)}
      />
    );
  }

  return (
    <>
      <PageLayout>
      <div className="container5 overflow-hidden">
        <div className="mt-0 ms-4 subCont5 pt-0">
          <h1>Your orders</h1>
          <p>(you will be able to buy links in already existing native articles - the most valuable ones)</p>

          <h2>Order summary (LaaS)</h2>
          <br/>
          {orders.map((order) => (
            <div key={order.id} className="mb-4">
              <h3>Order number: {order.id}</h3>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Keyword</th>
                      <th>Monthly Value</th>
                      <th>Type of Link</th>
                      <th>Publisher Website</th>
                      <th>Domain Authority</th>
                      <th>Exclusivity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.wordToLinks.map((item, index) => (
                      <tr key={index}>
                        <td className="d-flex justify-content-between align-items-center">
                          {item.keyword}
                          <span className="badge bg-secondary ms-2" style={{fontSize: '0.8em'}}>view</span>
                        </td>
                        <td>${item.monthlyValue}</td>
                        <td>{item.typeOfLink}</td>
                        <td>{item.publisherWebsite}</td>
                        <td>{item.domainAuthority}</td>
                        <td>{item.exclusivity ? 'Yes' : 'No'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="text-end mt-2" style={{ marginRight: '15px' }}>
              <strong>Total: ${calculateTotal(order.wordToLinks)}</strong>
            </div>
            </div>
          ))}
        </div>
      </div>
      </PageLayout>
    </>
  );
};

export default YourOrders;
