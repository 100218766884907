import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import './MyAccount.css';
import { BASE_API_URL } from './config/constants';
import {jwtDecode} from 'jwt-decode'; // Import jwtDecode function
import toast from 'react-hot-toast';

const MyAccount = () => {
  // const timeZones = [
  //   "Pacific/Midway",
  //   "Pacific/Honolulu",
  //   "America/Juneau",
  //   "America/Los_Angeles",
  //   "America/Tijuana",
  //   "America/Denver",
  //   "America/Phoenix",
  //   "America/Chicago",
  //   "America/Regina",
  //   "America/Mexico_City",
  //   "America/New_York",
  //   "America/Indiana/Indianapolis",
  //   "America/Bogota",
  //   "America/Lima",
  //   "America/Halifax",
  //   "America/Caracas",
  //   "America/Santiago",
  //   "America/St_Johns",
  //   "America/Sao_Paulo",
  //   "America/Argentina/Buenos_Aires",
  //   "America/Guyana",
  //   "Atlantic/Azores",
  //   "Atlantic/Cape_Verde",
  //   "Europe/London",
  //   "Europe/Berlin",
  //   "Europe/Belgrade",
  //   "Europe/Brussels",
  //   "Europe/Warsaw",
  //   "Africa/Algiers",
  //   "Europe/Bucharest",
  //   "Africa/Cairo",
  //   "Europe/Helsinki",
  //   "Europe/Minsk",
  //   "Asia/Jerusalem",
  //   "Europe/Moscow",
  //   "Asia/Kuwait",
  //   "Africa/Nairobi",
  //   "Asia/Baghdad",
  //   "Asia/Tehran",
  //   "Asia/Dubai",
  //   "Asia/Baku",
  //   "Asia/Kabul",
  //   "Asia/Yekaterinburg",
  //   "Asia/Karachi",
  //   "Asia/Kolkata",
  //   "Asia/Kathmandu",
  //   "Asia/Dhaka",
  //   "Asia/Colombo",
  //   "Asia/Almaty",
  //   "Asia/Rangoon",
  //   "Asia/Bangkok",
  //   "Asia/Krasnoyarsk",
  //   "Asia/Shanghai",
  //   "Asia/Kuala_Lumpur",
  //   "Asia/Taipei",
  //   "Australia/Perth",
  //   "Asia/Irkutsk",
  //   "Asia/Seoul",
  //   "Asia/Tokyo",
  //   "Asia/Yakutsk",
  //   "Australia/Darwin",
  //   "Australia/Adelaide",
  //   "Australia/Sydney",
  //   "Australia/Brisbane",
  //   "Australia/Hobart",
  //   "Asia/Vladivostok",
  //   "Pacific/Guam",
  //   "Asia/Magadan",
  //   "Pacific/Fiji",
  //   "Pacific/Auckland",
  //   "Pacific/Tongatapu"
  // ];
  const token = localStorage.getItem('token');
  const getUserName = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.sub; // Assuming 'sub' contains the username
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };
  const [activeTab, setActiveTab] = useState('Account Info');
  const [accountInfo, setAccountInfo] = useState({
    fullName: '',
    phone: '',
    company: '',
    country: 'Romania',
    timeZone: ''
  });
  const [email, setEmail] = useState({
    current: getUserName(token),
    new: ''
  });
  const [password, setPassword] = useState({
    current: '',
    new: '',
    confirm: '' // Added confirm password field
  });
  const [countries, setCountries] = useState([]); // State to hold countries
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [passwordError, setPasswordError] = useState(''); // Error message for password validation



  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        const response = await fetch(`${BASE_API_URL}api/myaccount/1`, { // Use the actual account ID
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setAccountInfo(data);
        } else {
          console.error('Failed to fetch account info');
        }
      } catch (error) {
        console.error('Error fetching account info:', error);
      }
    };

    fetchAccountInfo();
  }, [token]);

  // Fetch list of countries from API on component mount
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(`${BASE_API_URL}api/StaticValues/countries`);
        if (response.ok) {
          const countriesData = await response.json();
          setCountries(countriesData);
        } else {
          console.error('Failed to fetch countries');
        }
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

      fetchCountries(); // Call the API to fetch countries
    }, []);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleInputChange = (e, section) => {
    if (section === 'accountInfo') {
      setAccountInfo({ ...accountInfo, [e.target.name]: e.target.value });
    } else if (section === 'email') {
      setEmail({ ...email, [e.target.name]: e.target.value });
    } else if (section === 'password') {
      setPassword({ ...password, [e.target.name]: e.target.value });
    }
  };

  const getUserIdFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.nameid; // Adjust based on your token's structure
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };

  const userId = getUserIdFromToken(token);

  const handleSaveAccountInfo = async (e) => {
    e.preventDefault();

    // Validate necessary fields
    if (!accountInfo.fullName || !accountInfo.phone) {
        toast.error('Full Name and Phone are required.');
        return;
    }

    try {
        const response = await fetch(`${BASE_API_URL}api/myaccount/${userId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ ...accountInfo }),
        });

        if (response.status === 200) {
            toast.success("Account info has been saved!");
            // Optionally update the UI or reset fields here
        } else {
            const errorData = await response.json();
            toast.error(`Error: ${errorData?.message || "Failed to save account info"}`);
        }
    } catch (error) {
        toast.error("Error saving account info");
        console.error('Error saving account info:', error);
    }
};

  const handleSaveEmail = async (e) => {
    e.preventDefault();

    // Validate that the new email is not empty
    if (!email.new) {
        toast.error('New email cannot be empty.');
        return;
    }

    const payload = {
        newEmail: email.new
    };

    try {
        const response = await fetch(`${BASE_API_URL}api/myaccount/email`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
        });

        if (response.status === 200) {
            toast.success("Email was saved!");
            // Optionally clear the email field or redirect the user
            setEmail({ ...email, new: '' });
        } else {
            const errorData = await response.json();
            toast.error(`Error: ${errorData?.message || "Failed to save email"}`);
        }
    } catch (error) {
        toast.error("Error saving email!");
        console.error('Error saving email:', error);
    }
};

  const handleSavePassword = async (e) => {
    e.preventDefault();

    // Validate that new password and confirm password match
    if (password.new !== password.confirm) {
        setPasswordError('New password and confirm password do not match.');
        return;
    }

    const payload = {
        email: getUserName(token),  // Ensure this function returns the correct email
        currentPassword: password.current,
        newPassword: password.new
    };

    try {
        const response = await fetch(`${BASE_API_URL}api/authentication/set-new-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        if (response.status === 200) {
            toast.success("New password has been set!");
            // Optionally clear the password fields or redirect the user
            setPassword({
                current: '',
                new: '',
                confirm: ''
            });
        } else {
            const errorData = await response.json();
            toast.error(`Error: ${errorData?.message || "Failed to set new password"}`);
        }
    } catch (error) {
        toast.error("Error saving password!");
        console.error('Error saving password:', error);
    }
};

  return (
    <>
      <Navbar />
      <div className="my-account-page">
        <div className="container">
          <Sidebar />
          <div className="content">
            <h2>My Account</h2>
            <div className="tabs">
              <button className={activeTab === 'Account Info' ? 'active' : ''} onClick={() => handleTabClick('Account Info')}>Account Info</button>
              <button className={activeTab === 'Email' ? 'active' : ''} onClick={() => handleTabClick('Email')}>Email</button>
              <button className={activeTab === 'Password' ? 'active' : ''} onClick={() => handleTabClick('Password')}>Password</button>
              <button className={activeTab === 'Security' ? 'active' : ''} onClick={() => handleTabClick('Security')}>Security</button>
            </div>

            {activeTab === 'Account Info' && (
              <form className="form" onSubmit={handleSaveAccountInfo}>
                <div className="form-group">
                  <label>Full Name</label>
                  <input type="text" name="fullName" value={accountInfo.fullName} onChange={(e) => handleInputChange(e, 'accountInfo')} placeholder="Full Name" />
                </div>
                <div className="form-group">
                  <label>Phone</label>
                  <input type="tel" name="phone" value={accountInfo.phone} onChange={(e) => handleInputChange(e, 'accountInfo')} placeholder="Phone" />
                </div>
                <div className="form-group">
                  <label>Company</label>
                  <input type="text" name="company" value={accountInfo.company} onChange={(e) => handleInputChange(e, 'accountInfo')} placeholder="Company" />
                </div>
                <div className="form-group">
                  <label>Country</label>
                  <select name="country" value={accountInfo.country} onChange={(e) => handleInputChange(e, 'accountInfo')}>
                    <option value="">Select Country</option>
                    {countries.map((country, index) => (
                      <option key={index} value={country}>{country}</option>
                    ))}
                  </select>
                </div>
                {/* <div className="form-group">
                <label>Time Zone</label>
                <select name="timeZone" value={accountInfo.timeZone} onChange={(e) => handleInputChange(e, 'accountInfo')}>
                  <option value="">Select Time Zone</option>
                  {timeZones.map((zone) => (
                    <option key={zone} value={zone}>{zone}</option>
                  ))}
                </select>
              </div> */}
                <div className="button-group">
                  <button type="submit" className="save-btn">Save Changes</button>
                  <button type="button" className="cancel-btn">Cancel</button>
                </div>
              </form>
            )}

            {activeTab === 'Email' && (
              <form className="form" onSubmit={handleSaveEmail}>
                <div className="form-group">
                  <label>Current Email</label>
                  <input type="email" name="current" value={email.current} readOnly />
                </div>
                <div className="form-group">
                  <label>New Email</label>
                  <input type="email" name="new" value={email.new} onChange={(e) => handleInputChange(e, 'email')} placeholder="Enter New Email" />
                </div>
                <button type="submit" className="save-btn">Save Changes</button>
              </form>
            )}

            {activeTab === 'Password' && (
              <form className="form" onSubmit={handleSavePassword}>
                <div className="form-group">
                  <label>Current Password</label>
                  <input type="password" name="current" value={password.current} onChange={(e) => handleInputChange(e, 'password')} />
                </div>
                <div className="form-group">
                  <label>New Password</label>
                  <input type="password" name="new" value={password.new} onChange={(e) => handleInputChange(e, 'password')} />
                </div>
                <div className="form-group">
                  <label>Confirm Password</label>
                  <input type="password" name="confirm" value={password.confirm} onChange={(e) => handleInputChange(e, 'password')} />
                </div>
                {passwordError && <p className="error">{passwordError}</p>} {/* Display error message */}
                <button type="submit" className="save-btn">Change Password</button>
              </form>
            )}

            {activeTab === 'Security' && (
              <div className="security-section">
                <div className="toggle-group">
                  <input
                    type="checkbox"
                    id="twoFactorAuth"
                    checked={twoFactorAuth}
                    onChange={() => setTwoFactorAuth(!twoFactorAuth)}
                  />
                  <label htmlFor="twoFactorAuth">Enable 2-Factor Authentication via Email</label>
                </div>
                <p>Any time a login attempt is made, we will require a verification code.</p>
                <p>For more information on two-factor authentication, refer to our <a href="#">Help Center</a>.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );

};

export default MyAccount;
