import React from 'react';
import googleLogo from './assets/images/google-logo.png';
import { useNavigate } from 'react-router-dom';

const Statistics = ({ data, updateData }) => {
  const navigate = useNavigate();

  // No state or useEffect needed in this component

  return (
    <>
      <div className="d-sm-flex" style={{ width: '160%', gap: '1rem' }}>
        <button
          className="custom-google-btn px-1 py-0"
          onClick={() => {
            // Example of updating data and triggering API call on connect
            updateData({ connectedGoogleAnalytics: true }); // Update relevant data in parent component
            // Perform API call or other logic as needed
            navigate('#');
          }}
        >
          <img src={googleLogo} alt="Google Logo" className="google-logo" />
          Connect Google Analytics
        </button>
        <button
          className="custom-google-btn p-0"
          onClick={() => {
            // Similar logic as above
            updateData({ connectedGoogleSearchConsole: true }); // Update relevant data in parent component
            // Perform API call or other logic as needed
            navigate('#');
          }}
        >
          <img src={googleLogo} alt="Google Logo" className="google-logo " />
          Connect Google Search Console
        </button>
      </div>
    </>
  );
};

export default Statistics;
