import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';
import Checkout from './Checkout';
import { useCart } from './CartContext';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import toast from 'react-hot-toast';

const CartPage = () => {
  const { cartItems, removeFromCart, clearCart } = useCart();
  const [orderData, setOrderData] = useState(cartItems);
  const [showCheckout, setShowCheckout] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setOrderData(cartItems);
  }, [cartItems]);

  const calculateTotal = () => {
    return orderData.reduce((total, item) => total + item.monthlyValue, 0);
  };

  const handleDelete = (item) => {
    const index = cartItems.findIndex(cartItem => cartItem.id === item.id); // Use a unique property to find the index
    if (index !== -1) {
      removeFromCart(index);
    }
  };

  const getUserIdFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.nameid;
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };

  const handleOrderAndPay = async () => {
    const total = calculateTotal();
    const token = localStorage.getItem('token');
    const userId = getUserIdFromToken(token);
  
    if (!userId) {
      console.error("User ID not found");
      return;
    }
    setShowCheckout(true);
    //try {
      // const payload = {
      //   UserId: userId,
      //   WordToLinks: orderData.map(product => ({
      //     Keyword: product.keyword,
      //     Url: product.url,
      //     Title: product.title,
      //     Exclusivity: product.exclusivity,
      //     TypeOfLink: product.typeOfLink,
      //     PublisherWebsite: product.publisherWebsite,
      //     MonthlyValue: product.monthlyValue,
      //     DomainAuthority: product.domainAuthority
      //   })),
      // };
  
      // const response = await axios.post(`${BASE_API_URL}api/Order/${userId}`, payload, {
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Authorization': `Bearer ${token}`
      //   }
      // });
  
      // if (response.status === 200 || response.status === 201) {
      //   if (total <= 0) {
      //     // Set the order as paid only for free orders
      //     const orderId = response.data.id;
      //     const updateResponse = await axios.put(`${BASE_API_URL}api/Order/${orderId}/setPaid`, null, {
      //       headers: {
      //         'Content-Type': 'application/json',
      //         'Authorization': `Bearer ${token}`
      //       }
      //     });
  
      //     if (updateResponse.status !== 200) {
      //       throw new Error('Failed to update order payment status');
      //     }
  
      //     clearCart();
      //     toast.success('Free order placed and marked as paid successfully!');
      //     navigate('/Advertiser-step1');
      //   } else {
      //     // For paid orders, proceed to checkout without setting as paid
      //     setShowCheckout(true);
      //   }
      // } else {
      //   throw new Error('Failed to create order');
      // }
    // } catch (error) {
    //   console.error('Error processing order:', error);
    //   toast.error('An error occurred while processing your order. Please try again.');
    // }
  };

  if (showCheckout) {
    return (
      <Checkout 
        amount={calculateTotal()} 
        products={orderData}
      />
    );
  }

  return (
    <>
      <Navbar />
      <div className="container5 overflow-hidden">
        <Sidebar />
        <div className="mt-0 ms-4 subCont5 pt-0">
          <h1>Advertiser</h1>
          <p>(you will be able to buy links in already existing native articles - the most valuable ones)</p>

          <h2>Order summary (LaaS)</h2>

          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Keyword</th>
                  <th>Type of Link</th>
                  <th>Publisher Website</th>
                  <th>Domain Authority</th>
                  <th>Monthly Value</th>
                  <th>Exclusivity</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {orderData.map((item, index) => (
                  <tr key={index}>
                    <td className="d-flex justify-content-between align-items-center">
                      {item.keyword}
                      <span className="badge bg-secondary ms-2" style={{fontSize: '0.8em'}}>view</span>
                    </td>
                    <td>{item.typeOfLink}</td>
                    <td>{item.publisherWebsite}</td>
                    <td>{item.domainAuthority}</td>
                    <td>${item.monthlyValue}</td>
                    <td>{item.exclusivity ? 'Yes' : 'No'}</td>
                    <td>
                      <button
                        className="text-primary" 
                        onClick={(e) => {
                          e.preventDefault();
                          handleDelete(item);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <p>Total for {orderData.length} links in native articles</p>
          <h3>TOTAL: ${calculateTotal()} USD / Month</h3>
          <p>These hyperlinks are Links as a Service (LaaS), the links will remain active as long as you pay monthly.</p>

          <div className="mt-4 d-flex justify-content-between" style={{ maxWidth: '400px', marginBottom: '20px' }}>
            <button className="btn btn-primary me-2 w-50" onClick={handleOrderAndPay}>
              {calculateTotal() === 0 ? 'Place Order' : 'Order and Pay'}
            </button>
            <button className="btn w-50" style={{ backgroundColor: 'green', color: 'white' }} onClick={() => navigate('/Advertiser-step1')}>Continue Shopping</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartPage;