import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Correct import
import { useCart } from './CartContext';

const fetchRecommendedPrice = async (entry, domainAuthority, linkType, exclusivity) => {
    try {
        const response = await axios.get(`${BASE_API_URL}api/priceCalculator/calculate`, {
            params: {
                domainAuthority,
                exclusivity,
                linkType,
                price: entry.price
            }
        });
        return response.data.totalPrice;
    } catch (error) {
        console.error("Error fetching recommended price:", error);
        return null;
    }
};

export default function Modal({ entry, onClose }) {
    const [isPriceLoading, setIsPriceLoading] = useState(false);
    const { addToCart } = useCart();
    const navigate = useNavigate();
    const [recommendedPrice, setRecommendedPrice] = useState(null);
    const [formData, setFormData] = useState({
        keyword: '',
        addURL: '',
        title: '',
        exclusivity: false,
        linkType: 'NormalFollow', // Default link type
    });

    const [errors, setErrors] = useState({
        keyword: false,
        addURL: false
    });


    useEffect(() => {
        if (entry && entry.domainAuthority) {
            setIsPriceLoading(true);
            fetchRecommendedPrice(entry, entry.domainAuthority, formData.linkType, formData.exclusivity)
                .then(price => {
                    setRecommendedPrice(price);
                    setIsPriceLoading(false);
                });
        }
    }, [entry, formData.linkType, formData.exclusivity]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'radio') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                linkType: name,
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
    };

    useEffect(() => {
        if (entry) {
            setFormData(prevFormData => ({
                ...prevFormData,
                ...entry,
                linkType: entry.linkType || 'NormalFollow' // Ensure linkType is set to 'NormalFollow' if not provided
            }));
        }
    }, [entry]);

    const getUserIdFromToken = (token) => {
        try {
            const decodedToken = jwtDecode(token);
            return decodedToken.nameid; // Adjust based on your token's structure
        } catch (error) {
            console.error("Invalid token", error);
            return null;
        }
    };

    const validateForm = () => {
        const { keyword, addURL } = formData;
        let valid = true;
        const newErrors = { keyword: false, addURL: false };

        if (!keyword.trim()) {
            newErrors.keyword = true;
            valid = false;
        }
        // Simple URL validation regex
        const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-zA-Z0-9\\-\\.]+)\\.([a-zA-Z]{2,6})' + // domain
            '|[0-9a-fA-F]{1,4}(:[0-9a-fA-F]{1,4}){7})' + // ipv6
            '|localhost)' + // localhost
            '(\\:[0-9]{1,5})?' + // port
            '(\\/.*)?$'); // path

        if (!urlPattern.test(addURL)) {
            newErrors.addURL = true;
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return; // Prevent submission if validation fails
        }

        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        const linkTypes = {
            NormalFollow: 'NormalFollow',
            NoFollow: 'NoFollow',
            UGC: 'UGC',
            Sponsored: 'Sponsored',
            GeneralKeyword: 'GeneralKeyword'
        };

        const selectedLinkType = linkTypes[formData.linkType] || 'NormalFollow';
        const userId = getUserIdFromToken(token);

        const payload = {
            UserId: userId,
            WordToLinks: [
                {
                    Keyword: formData.keyword,
                    Url: formData.addURL,
                    Title: formData.title,
                    Exclusivity: formData.exclusivity,
                    TypeOfLink: selectedLinkType,
                    PublisherWebsite: entry.domain,
                    MonthlyValue: recommendedPrice,
                }
            ]
        };

        try {
            // Add the item to the cart
            addToCart({
                id: userId,
                keyword: formData.keyword,
                url: formData.addURL,
                title: formData.title,
                exclusivity: formData.exclusivity,
                typeOfLink: selectedLinkType,
                publisherWebsite: entry.domain,
                monthlyValue: recommendedPrice,
                domainAuthority: entry.domainAuthority
            });

            navigate('/cart');
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <div className="modal overflow-hidden" style={{ display: 'block', backgroundColor: 'rgb(121, 121, 121, 0.60)' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="">
                            <div className="d-flex p-3 justify-content-end">
                                <button type="button" className="close border bg-transparent" onClick={onClose}>
                                    <span style={{ fontWeight: '700', fontSize: '20px' }} className='text-dark'>X</span>
                                </button>
                            </div>
                            <h2 style={{ fontWeight: '700' }} className="modal-title px-5">Add your keyword**</h2>
                            <div className="modal-body px-5">
                                <div className="form-group">
                                    <div className='d-flex justify-content-between'>
                                        <h6 htmlFor="keyword">Keyword you want to optimize for:</h6>
                                        <p className="font-italic me-2">𝒊</p>
                                    </div>
                                    <input
                                        placeholder="Enter your keyword"
                                        type="text"
                                        id="keyword"
                                        name="keyword"
                                        value={formData.keyword}
                                        onChange={handleChange}
                                        className={`form-control ${errors.keyword ? 'is-invalid' : ''}`}
                                    />
                                    {errors.keyword && <div className="invalid-feedback">Keyword is required.</div>}
                                </div>
                                <div className="form-group">
                                    <div className='d-flex justify-content-between'>
                                        <h6 htmlFor="addURL">Add your URL:</h6>
                                        <p className="font-italic me-2">𝒊</p>
                                    </div>
                                    <input
                                        placeholder="Add your URL"
                                        type="text"
                                        id="addURL"
                                        name="addURL"
                                        value={formData.addURL}
                                        onChange={handleChange}
                                        className={`form-control ${errors.addURL ? 'is-invalid' : ''}`}
                                        />
                                        {errors.addURL && <div className="invalid-feedback">Please enter a valid URL.</div>}
                                </div>
                                <div className="form-group">
                                    <div className='d-flex justify-content-between'>
                                        <h6 htmlFor="title">Title:</h6>
                                        <p className="font-italic me-2">𝒊</p>
                                    </div>
                                    <input
                                        placeholder="Title ahref"
                                        type="text"
                                        id="title"
                                        name="title"
                                        value={formData.title}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div style={{ color: 'black' }} className="checkbox-group form-group">
                                    <input
                                        style={{ width: '20px' }}
                                        type="checkbox"
                                        id="exclusivity"
                                        name="exclusivity"
                                        checked={formData.exclusivity}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="exclusivity">
                                        I want Exclusivity (+40% of the base price)
                                    </label>
                                </div>
                                <div className="form-group">
                                    <div style={{ color: 'black' }} className="radio-group d-flex align-items-center form-group">
                                        <input
                                            style={{ width: '20px' }}
                                            type="radio"
                                            id="NormalFollow"
                                            name="NormalFollow"
                                            checked={formData.linkType === 'NormalFollow'}
                                            onChange={handleChange}
                                        />
                                        <div className='d-flex gap-4 align-items-center'>
                                            <label htmlFor="dofollowLink">
                                                Dofollow Link
                                            </label>
                                            <p className="font-italic my-auto">𝒊</p>
                                        </div>
                                    </div>
                                    <div style={{ color: 'black' }} className="radio-group d-flex align-items-center form-group">
                                        <input
                                            style={{ width: '20px' }}
                                            type="radio"
                                            id="NoFollow"
                                            name="NoFollow"
                                            checked={formData.linkType === 'NoFollow'}
                                            onChange={handleChange}
                                        />
                                        <div className='d-flex gap-4 align-items-center'>
                                            <label htmlFor="nofollowLink">
                                                Nofollow Link
                                            </label>
                                            <p className="font-italic my-auto">𝒊</p>
                                        </div>
                                    </div>
                                    <div style={{ color: 'black' }} className="radio-group d-flex align-items-center form-group">
                                        <input
                                            style={{ width: '20px' }}
                                            type="radio"
                                            id="UGC"
                                            name="UGC"
                                            checked={formData.linkType === 'UGC'}
                                            onChange={handleChange}
                                        />
                                        <div className='d-flex gap-4 align-items-center'>
                                            <label htmlFor="ugcLink">
                                                UGC Link
                                            </label>
                                            <p className="font-italic my-auto">𝒊</p>
                                        </div>
                                    </div>
                                    <div style={{ color: 'black' }} className="radio-group d-flex align-items-center form-group">
                                        <input
                                            style={{ width: '20px' }}
                                            type="radio"
                                            id="Sponsored"
                                            name="Sponsored"
                                            checked={formData.linkType === 'Sponsored'}
                                            onChange={handleChange}
                                        />
                                        <div className='d-flex gap-4 align-items-center'>
                                            <label htmlFor="Sponsored">
                                                Sponsored Link
                                            </label>
                                            <p className="font-italic my-auto">𝒊</p>
                                        </div>
                                    </div>
                                    <div style={{ color: 'black' }} className="radio-group d-flex align-items-center form-group">
                                        <input
                                            style={{ width: '20px' }}
                                            type="radio"
                                            id="GeneralKeyword"
                                            name="GeneralKeyword"
                                            checked={formData.linkType === 'GeneralKeyword'}
                                            onChange={handleChange}
                                        />
                                        <div className='d-flex gap-4 align-items-center'>
                                            <label htmlFor="generalLink">
                                                General Link (here, click here, tap here)
                                            </label>
                                            <p className="font-italic my-auto">𝒊</p>
                                        </div>
                                    </div>
                                </div>
                                <div 
                                    style={{
                                        width: '60%', 
                                        margin: '0 auto', 
                                        padding: '', 
                                        textAlign: 'center', 
                                        backgroundColor: '#f8f9fa', 
                                    }}
                                >
                                    <div style={{ marginBottom: '' }}>
                                        <span 
                                            style={{ 
                                                fontSize: '28px', 
                                                fontWeight: 'bold', 
                                                color: '#0d6efd' 
                                            }}
                                        >
                                            ${recommendedPrice}
                                        </span>
                                    </div>
                                    <button 
                                        onClick={() => handleSubmit()} 
                                        type="button" 
                                        className="btn btn-primary" 
                                        disabled={isPriceLoading || recommendedPrice === null}
                                        style={{
                                            fontSize: '16px', 
                                            fontWeight: '500', 
                                            padding: '10px 20px', 
                                            borderRadius: '5px', 
                                            backgroundColor: '#0d6efd'
                                        }}
                                    >
                                        {isPriceLoading ? 'Calculating Price...' : 'Add your keyword'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
