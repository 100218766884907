import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Correct import
import { BASE_API_URL } from '../src/config/constants';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(null);
  const [userType, setUserType] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      const decodedUser = jwtDecode(storedToken);
      setUser(decodedUser);
      setToken(storedToken);
      setIsLoggedIn(true);
      console.log(decodedUser.userType);
      setUserType(decodedUser.userType);
    } else {
      setIsLoggedIn(false);
      setToken(null);
      setUserType(null);
    }
  },[token]);

  const getUserTypeFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.userType;
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };

  const register = async (registerRequest) => {
    try {
      await axios.post(`${BASE_API_URL}api/authentication/register`, registerRequest);
      setUser({ email: registerRequest.email, userType: registerRequest.userType });
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error('Registration failed: Account already exists');
        throw new Error('Account already exists');
      } else {
        console.error('Registration failed:', error);
        throw new Error('Registration failed');
      }
    }
  };

  const login = async (loginRequest) => {
    try {
      const response = await axios.post(`${BASE_API_URL}api/authentication/login`, loginRequest);
      const token = response.data.token;
      localStorage.setItem('token', token);
      const decodedUser = jwtDecode(token);
      setUser(decodedUser);
      setToken(token);
      setUserType(decodedUser.userType);
      setIsLoggedIn(true);
      let userType = decodedUser.userType;
      if (userType === 'sysadmin')
        navigate(`/AdminPanel`);
      else
        navigate(`/${decodedUser.userType}`);
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  };
  const resetPassword = async (email) => {
    try {
      await axios.post(`${BASE_API_URL}api/authentication/reset-password`, { email });
    } catch (error) {
      console.error('Password reset failed:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await axios.post(`${BASE_API_URL}api/authentication/logout`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      localStorage.removeItem('token');
      setIsLoggedIn(false);
      setUser(null);
      setToken(null);
      setUserType(null);
      navigate('/login');
    }
  };

  const googleSignIn = async (idToken) => {
    try {
      const formData = new FormData();
      formData.append('credential', idToken);
      const response = await axios.post(`${BASE_API_URL}api/authentication/google-signin`, formData, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      });
      localStorage.setItem('token', response.data.token);
      const payload = jwtDecode(response.data.token);
      setUser({ email: payload.sub, userType: getUserTypeFromToken(response.data.token) });
      setIsLoggedIn(true);
      setToken(response.data.token);

      const getUserTypeResponse = await axios.get(`${BASE_API_URL}api/User/email/${payload.sub}`);
      if (getUserTypeResponse.data) {
        setUser({ email: payload.sub, userType: getUserTypeResponse.data });
        navigate(`/${getUserTypeResponse.data}`);
      } else {
        navigate('/choosetype');
      }
    } catch (error) {
      console.error('Google sign-in failed:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ user, setUser,setToken,setUserType, setIsLoggedIn, isLoggedIn, register, login, resetPassword, logout, googleSignIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
