import React, { useState, useEffect } from "react";
import FileInput from "./components/FileInput";

const Popularity = ({ data, updateData, errors, setErrors }) => {
  const [formData, setFormData] = useState(data);

  const handleFileSelect = (files) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      file: files[0],
    }));
  };

  console.log("Popularity data", data);
  // Update parent component data whenever formData changes
  useEffect(() => {
    updateData(formData);
  }, [formData]);

  return (
    <>
      <form style={{ width: "150%" }}>
        <div className="form-group">
          <label htmlFor="">Number of unique users per month *</label>
          <input
  placeholder="Number of visitors"
  type="text"
  value={formData.uniqueUsers || ""}
  onChange={(e) => {
    const { name, value } = e.target;

    // Update form data
    setFormData((prevFormData) => ({
      ...prevFormData,
      uniqueUsers: value,
    }));

    // Clear the error for this field
    setErrors((prevErrors) => ({
      ...prevErrors,
      popularityData: {
        ...prevErrors.popularityData,
        uniqueUsers: '', // Clear the error message for "uniqueUsers"
      },
    }));
  }}
  name="uniqueUsers" // Make sure to include the name attribute
  required
  className={`form-control ${errors && errors.uniqueUsers ? 'is-invalid' : ''}`}
/>
{errors && errors.uniqueUsers && <div className="text-danger">{errors.uniqueUsers}</div>}

        </div>
        <div className="form-group">
          <label htmlFor="file">
            Attach files documenting the number of visitors (PDF)
          </label>
          <FileInput onFileSelect={handleFileSelect} data={data} />
          <small style={{ color: "#8f8f8f" }}>
            Add statistics generated directly from Google Analytics for the last
            three months documenting the number of unique users.
          </small>
        </div>
      </form>
    </>
  );
};

export default Popularity;
