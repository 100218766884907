import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import "./PublisherStep1.css";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import Popularity from "./Popularity";
import Statistics from "./Statistics";
import Theme from "./Theme";
import BasicData from "./BasicsData";
import { Col } from "reactstrap";
import PageLayout from './components/PageLayout';
import { BASE_API_URL } from '../src/config/constants';
import { Alert } from "react-bootstrap";

const PublisherStep1 = () => {
  const [formData, setFormData] = useState({
    basicData: {},
    themeData: {},
    popularityData: {},
    statisticsData: {},
  });
  const [activeTab, setActiveTab] = useState("basic");
  const navigate = useNavigate();
  const updateData = (newData) => {
    setFormData((prevData) => ({
      ...prevData,
      [`${activeTab}Data`]: newData,
    }));
  };

  const [error, setError] = useState(''); //bootstrap alert
  const [success, setSuccess] = useState('');
  const [errors, setErrors] = useState({
    basicData: {
      domain: '',
      language: '',
      country: '',
      websiteType: '',
      occurrences: '',
      price: '',
      indexing: '',
      customPrice: ''
    },
    themeData: {
      description: '',
      language: '',
      acceptedCategories: '',
      blockedWebsites: ''
    },
    popularityData: {
      uniqueUsers: '',
      file: ''
    },
    statisticsData: {
      // Add any fields that need validation in the statistics step
    }
  });

  const validateCurrentTab = () => {
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}\/$/;
    const currentData = formData[`${activeTab}Data`];
    let newErrors = {};
    let isValid = true;
  
    console.log(currentData);
    switch (activeTab) {
      case "basic":
        if (!urlPattern.test(currentData.domain)) 
          // Check if it is missing a slash, but ensure it doesn't already have a path
          if (!currentData.domain.endsWith("/")) {
            currentData.domain = currentData.domain + "/";
          }
      
      newErrors.basicData = {
        domain: !currentData.domain ? "Please fill Domain" : 
                !urlPattern.test(currentData.domain) ? "Invalid domain name. Please use example.com format or example.com/" : "",
        language: !currentData.language || currentData.language === "" ? "Please select a language" : "",
        country: !currentData.country ? "Please select Country" : "",
        websiteType: !currentData.websiteType ? "Please select Website Type" : "",
        customPrice: (currentData.useCustomPrice && !currentData?.customPrice) ? "Please insert custom price" : ""


        // Add other fields as needed
      };
      if (
        newErrors.basicData.domain ||
        newErrors.basicData.language ||
        newErrors.basicData.country ||
        newErrors.basicData.websiteType ||
        newErrors.basicData.customPrice
      ) {
        isValid = false;
      }
      break;
  
      case "theme":
    newErrors.themeData = {
      description: !currentData.description ? "Please add a description" : "",
      language: !currentData.language ? "Please add at least one tag" : "",
    };

    if (
      newErrors.themeData.description ||
      newErrors.themeData.language 
    ) {
      isValid = false;
    }
    break;
  
    case "popularity":
      newErrors.popularityData = {
        uniqueUsers: !currentData.uniqueUsers ? "Please fill Users" : ""
      };
  
      if (
        newErrors.popularityData.uniqueUsers
      ) {
        isValid = false;
      }
      break;
    }
  
    setErrors(newErrors);
    return isValid;
  };

  const onNext = () => {
    if (validateCurrentTab()) {
      if (activeTab === "basic") {
        setActiveTab("theme");
      } else if (activeTab === "theme") {
        setActiveTab("popularity");
      } else if (activeTab === "popularity") {
        setActiveTab("statistics");
      }
    }
  };

  const onBack = () => {
    if (activeTab === "statistics") {
      setActiveTab("popularity");
    } else if (activeTab === "popularity") {
      setActiveTab("theme");
    } else if (activeTab === "theme") {
      setActiveTab("basic");
    }
  };
  const onFinalize = async () => {
    if(!validateCurrentTab()){
      return;
    }
    try {
      const data = new FormData();
      
      console.log('istrialmode',formData.basicData.isTrialMode);
      console.log('customprice',formData.basicData.customPrice);
      console.log('baseprice',formData.basicData.basePrice);
      console.log('basicData',formData.basicData);
      data.append("Domain", formData.basicData.domain);
      data.append("Language", formData.basicData.language);
      data.append("Country", formData.basicData.country);
      data.append("WebsiteType", formData.basicData.websiteType);
      data.append("Indexing", formData.basicData.indexing !== undefined ? formData.basicData.indexing : false);
      data.append("Price", formData.basicData.isTrialMode ? 0.001 : (formData.basicData.customPrice || formData.basicData.basePrice || formData.basicData.price || 0));
      data.append("Description", formData.themeData.description);
      data.append("AcceptedCategories", formData.themeData.acceptedCategories);
      data.append("BlockedWebsites", formData.themeData.blockedWebsites);
      data.append("UniqueUsers", formData.popularityData.uniqueUsers);
      data.append("TrustFlow", formData.basicData.trustFlow);
      data.append("URMedium", formData.basicData.URMedium);
      data.append("Dofollow", formData.basicData.Dofollow);
      data.append("TrafficTracking", formData.basicData.trafficTracking);
      data.append("Occurences", formData.basicData.occurrences); //TODO: typo occurences
      data.append("DomainAuthority", formData.basicData.domainAuthority);
    //   try {
    //     const response = await fetch(`${BASE_API_URL}api/seo/bulk-ranks`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify([formData.basicData.domain])
    //     });
    //     if (!response.ok) {
    //         throw new Error('Network response from seo bulk-ranks was not ok');
    //     }
    //     const jsonData = await response.json();
    //     console.log(jsonData);
    //     const rank = jsonData.tasks[0].result[0].items[0].rank;
    //     data.append("DomainAuthority", rank);
    // } catch (error) {
    //     console.error('Error fetching data for seo bulk-ranks:', error);
    // }
      //data.append("QualitativeEvaluation", formData.basicData.qualitativeEvaluation);
      //data.append("TechnicalEvaluation", formData.basicData.technicalEvaluation);
  
      // Append file if it exists
    // Handle file if it exists
    if (formData.popularityData.file) {
      const file = formData.popularityData.file;
      const base64String = await fileToBase64(file);
      data.append("VisitorStatistics", base64String);
    }
    function fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
    
        reader.onload = () => {
          const base64String = reader.result.split(',')[1]; // Get the Base64 string without the prefix
          resolve(base64String);
        };
    
        reader.onerror = () => {
          reader.abort();
          reject(new Error('Error reading file'));
        };
    
        reader.readAsDataURL(file);
      });
    }
      // Log the FormData entries for debugging
      for (let pair of data.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }
      console.log(data);
      // const response = await axios.post(
      //   "https://adverlinkfrontend20240516123335.azurewebsites.net/api/userdomains/submit", 
      //   data, 
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );

      const token = localStorage.getItem('token'); // Replace 'yourAuthToken' with your actual token key

      // Fetch request options with Authorization header
      const requestOptions = {
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${token}`, // Include token in Authorization header
        },
      };
  
      // Send fetch request
      const response = await fetch(
        `${BASE_API_URL}api/userdomains/submit`,
        requestOptions
      );

      if (response.ok) {
        setSuccess("Data submitted successfully");
        navigate("/publisher-step2");
      } else {
        const errorData = await response.json();
        console.error("Error submitting data:", errorData);
        setError("Failed to submit data");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      setError("Failed to submit data");
    }
  };
  

  const renderActiveTab = () => {
    switch (activeTab) {
      case "theme":
        return <Theme data={formData.themeData} updateData={updateData} errors={errors.themeData} setErrors={setErrors} />;
      case "popularity":
        return (
          <Popularity data={formData.popularityData} updateData={updateData} errors={errors.popularityData} setErrors={setErrors} />
        );
      case "statistics":
        return (
          <Statistics data={formData.statisticsData} updateData={updateData} />
        );
      default:
        return <BasicData data={formData.basicData} updateData={updateData} errors={errors.basicData} setErrors={setErrors} />;
    }
  };

  return (
    <PageLayout>
      <div className="main mt-0 pt-0">
        <div className="header">
          <h5>Publisher</h5>
        </div>
        <p>
          (website owner, blog owner, corporate websites, personal websites)
        </p>
        {error && (
        <Alert variant="danger" onClose={() => setError('')} dismissible>
          {error}
        </Alert>
      )}
                {success && (
            <Alert variant="success" onClose={() => setSuccess('')} dismissible>
              {success}
            </Alert>
          )}
        <div className="nav-tabs">
          <a
            href="#."
            onClick={(e) => e.preventDefault()}
            className={activeTab === "basic" ? "nav-tab active" : "nav-tab"}
          >
            Basic data
          </a>
          <a
            href="#."
            onClick={(e) => e.preventDefault()}
            className={activeTab === "theme" ? "nav-tab active" : "nav-tab"}
          >
            Theme
          </a>
          <a
            href="#."
            onClick={(e) => e.preventDefault()}
              className={
                activeTab === "popularity" ? "nav-tab active" : "nav-tab"
              }
          >
            Popularity
          </a>
          {/* <a
            href="#."
            onClick={(e) => e.preventDefault()}
            className={
              activeTab === "statistics" ? "nav-tab active" : "nav-tab"
            }
          >
            Statistics
          </a> */}
        </div>

        <form style={{ width: "70%" }} onSubmit={(e) => e.preventDefault()}>
          {renderActiveTab()}
        </form>
        <div className="form-actions mb-3">
          <button
            type="button"
            className="btn btn-primary"
            onClick={onBack}
            disabled={activeTab === "basic"}
          >
            Back
          </button>
          {activeTab === "popularity" ? (
            <button
              type="button"
              className="btn btn-primary"
              onClick={onFinalize}
            >
              Finalize
            </button>
          ) : (
            <button
              disabled={
                activeTab === "basic" && 
                !formData?.basicData?.domainStatus?.isCalculated
              }
              type="button"
              className="btn btn-primary"
              onClick={onNext}
            >
              Next
            </button>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default PublisherStep1;
