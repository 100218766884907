import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faShoppingCart, faSignOutAlt, faWallet } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../authService";
import { useCart } from "../CartContext";
import NotificationDropdown from "./NotificationDropdown";
import { useNotifications } from "./NotificationContext";

const getUserName = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.sub; // Assuming 'sub' contains the username
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
};

const getUserTypeFromToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.userType; // Assuming 'userType' is a claim in the token
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
};

const Navbar = () => {
  const { setIsLoggedIn, setToken, setUserType } = useAuth();
  const { notifications, setNotifications } = useNotifications();
  const { cartItems } = useCart();
  const [showNotifications, setShowNotifications] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem('token');
  const userName = getUserName(token);
  const userTypeFromToken = getUserTypeFromToken(token);
  const cartItemCount = cartItems ? cartItems.length : 0;

  // Calculate the unread notification count
  const unreadCount = notifications.filter(notification => !notification.isRead).length;

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setToken(null);
    setUserType(null);
    window.location.href = '/login'; // Refreshes the page
  };

  const handleCartClick = () => {
    navigate('/cart');
  };

  const handleWalletClick = () => {
    navigate('/wallet');
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  // Inline styles for hover effects
  const iconStyle = {
    color: "#8f8f8f", // Default grey color
    marginLeft: '10px',
    cursor: 'pointer',
    transition: 'color 0.3s, transform 0.3s',
  };

  const [walletIconStyle, setWalletIconStyle] = useState(iconStyle);
  const [accountIconStyle, setAccountIconStyle] = useState(iconStyle);
  const [notificationIconStyle, setNotificationIconStyle] = useState(iconStyle);
  const [logoutIconStyle, setLogoutIconStyle] = useState(iconStyle);
  const [cartIconStyle, setCartIconStyle] = useState(iconStyle); // State for cart icon style

  const handleAccountMouseOver = () => {
    setAccountIconStyle({
      ...iconStyle,
      color: "#007bff", // Change color on hover
      transform: 'scale(1.1)',
    });
  };

  const handleAccountMouseOut = () => {
    setAccountIconStyle(iconStyle);
  };

  const handleNotificationMouseOver = () => {
    setNotificationIconStyle({
      ...iconStyle,
      color: "#007bff",
      transform: 'scale(1.1)',
    });
  };

  const handleNotificationMouseOut = () => {
    setNotificationIconStyle(iconStyle);
  };

  const handleLogoutMouseOver = () => {
    setLogoutIconStyle({
      ...iconStyle,
      color: "#FF4C4C",
      transform: 'scale(1.1)',
    });
  };

  const handleLogoutMouseOut = () => {
    setLogoutIconStyle(iconStyle);
  };

  const handleWalletMouseOver = () => {
    setWalletIconStyle({
      ...iconStyle,
      color: "#007bff", // Change color on hover to blue
      transform: 'scale(1.1)',
    });
  };

  const handleWalletMouseOut = () => {
    setWalletIconStyle(iconStyle); // Reset to original style
  };

  const handleCartMouseOver = () => {
    setCartIconStyle({
      ...iconStyle,
      color: "#007bff", // Change color on hover to blue
      transform: 'scale(1.1)',
    });
  };

  const handleCartMouseOut = () => {
    setCartIconStyle(iconStyle); // Reset to original style
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light d-inline-flex justify-content-evenly d-sm-flex justify-content-sm-between px-4 py-3">
      <a className="navbar-brand" onClick={(e) => {
        e.preventDefault();
        navigate(userTypeFromToken === 'advertiser' ? "/advertiser" : "/publisher");
      }}>
        <img src={require("../assets/images/Link-Building-Software.png")} alt="AdverLink" style={{ width: "150px" }} />
      </a>

      <div className="d-flex align-items-center">
        {/* Logout Icon */}
        <FontAwesomeIcon
          icon={faSignOutAlt}
          size="xl"
          style={logoutIconStyle}
          onMouseOver={handleLogoutMouseOver}
          onMouseOut={handleLogoutMouseOut}
          onClick={handleLogout}
        />
        <span style={{ marginLeft: '10px' }}>{userName}</span>

        {/* Cart for advertiser */}
        {userTypeFromToken === 'advertiser' && (
          <button onMouseOver={handleCartMouseOver} onMouseOut={handleCartMouseOut} onClick={handleCartClick}
                  style={{ position: 'relative', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>
            <FontAwesomeIcon icon={faShoppingCart} size="xl" style={cartIconStyle} /> {/* Use cart icon style */}
            {cartItemCount > 0 && (
              <span className="badge bg-danger rounded-pill" style={{ position: 'absolute', top: '-5px', right: '-10px' }}>
                {cartItemCount}
              </span>
            )}
          </button>
        )}

        {/* Wallet icon for advertiser */}
        {userTypeFromToken === 'advertiser' && (
          <button
            onMouseOver={handleWalletMouseOver}
            onMouseOut={handleWalletMouseOut}
            onClick={handleWalletClick}
            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
          >
            <FontAwesomeIcon
              icon={faWallet} // Use FontAwesome wallet icon
              size="xl"
              style={walletIconStyle} // Apply wallet icon style
            />
          </button>
        )}

        <a href="/myaccount" style={{ textDecoration: 'none' }}>
          <FontAwesomeIcon
            icon={faUser}
            size="xl"
            style={accountIconStyle}
            onMouseOver={handleAccountMouseOver}
            onMouseOut={handleAccountMouseOut}
          />
        </a>

        {/* Notification Icon */}
        <div style={{ position: 'relative' }}>
          <FontAwesomeIcon
            icon={faBell}
            size="xl"
            style={notificationIconStyle}
            onMouseOver={handleNotificationMouseOver}
            onMouseOut={handleNotificationMouseOut}
            onClick={toggleNotifications}
          />
          {unreadCount > 0 && (
            <span className="badge bg-danger rounded-pill" style={{ position: 'absolute', top: '-5px', right: '-10px' }}>
              {unreadCount}
            </span>
          )}
          {showNotifications && <NotificationDropdown notifications={notifications} setNotifications={setNotifications} />}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
