import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import MyLineChart from './components/LineChart';
import {
    Table,
    Badge,
    UncontrolledDropdown,
    DropdownItem,
    Input
} from "reactstrap";
import { useAuth } from './authService';
import { useNavigate } from 'react-router-dom';
import DomainKeyModal from './DomainKeyModal';  // Import the modal component
import { BASE_API_URL } from './config/constants';

const PublisherStep2 = () => {
    const [data, setData] = useState([]);
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState('');
    const [secretKeys, setSecretKeys] = useState({}); // To store secret keys
    const [secretKeyWasGenerated, setSecretKeyWasGenerated] = useState(false); //TODO: maybe better way

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }
    
            const domainResponse = await axios.get(`${BASE_API_URL}api/userdomains/publisher-get`, {           
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const domains = domainResponse.data;
    
            const keyResponse = await axios.get(`${BASE_API_URL}api/userdomains/get-secret-keys`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const keys = keyResponse.data;
    
            const keyMap = keys.reduce((acc, keyObj) => {
                acc[keyObj.domain] = keyObj.secretKey;
                return acc;
            }, {});
    
            const parsedData = domains.map(item => ({
                ...item,
                secretKey: keyMap[item.domain] || 'N/A'
            }));
            setData(parsedData);
            setSecretKeys(keyMap);
        } catch (error) {
            console.error('Error fetching data:', error);
            alert('Failed to fetch data. Please try again later.'); // User feedback
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            fetchData();
        } else {
            navigate('/login');
        }
    }, [isLoggedIn, navigate, secretKeyWasGenerated]);

    const toggleModal = (domain = '') => {
        setSelectedDomain(domain);
        setModalOpen(!modalOpen);
    };

    const ActionButton = ({ domain }) => {
        return (
            <div className="d-flex">
                <UncontrolledDropdown>
                    <div className="d-flex gap-2">
                        <DropdownItem className="text-center" onClick={() => toggleModal(domain)}>
                            <Badge color="info">Generate secret key</Badge>
                        </DropdownItem>
                    </div>
                </UncontrolledDropdown>
            </div>
        );
    };

    return (
        <>
            <Navbar />
            <div className='container3'>
                <Sidebar />
                <div className="main2 ">
                    <div className="header">
                        <h5>Publisher</h5>
                    </div>
                    <p>(website owner, blog owner, corporate websites, personal websites)</p>
                    {data.length > 0 ? (
                        <>
                            <h6 className='my-4'>Keywords in your publisher network</h6>
                            <div className='bg-primary p-2 rounded text-white'>Keyword</div>
                            <div className="table-responsive" style={{ maxHeight: '400px', overflowY: data.length > 10 ? 'scroll' : 'auto' }}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Website</th>
                                            <th scope="col">Monthly fee</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Intervention needed</th>
                                            <th scope="col">Secret key</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => (
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{item.description}</td>
                                                <td>{item.domain}</td>
                                                <td>{item.price} USD + VAT</td>
                                                <td className=''>Paid</td>
                                                <td>{/* Intervention needed value */}</td>
                                                <td className=''>
                                                    <Input
                                                        type="text"
                                                        value={item.secretKey}
                                                        readOnly
                                                        className="cursor-pointer"
                                                        onClick={() => navigator.clipboard.writeText(item.secretKey)}
                                                        title="Click to copy"
                                                    />
                                                    <small className="text-muted cursor-pointer" onClick={() => navigator.clipboard.writeText(item.secretKey)}>
                                                        Click to copy
                                                    </small>
                                                </td>
                                                <td>
                                                    <ActionButton domain={item.domain} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </>
                    ) : (
                        <div className="text-center my-5">
                            <p>No data found.</p>
                        </div>
                    )}
                    {/* <div className='my-5 chartHead'>
                        <h6 className='my-4'>Income last 30 days / link as a service concept</h6>
                        <div className='text-center'>Keyword Evolution vs. Revenue Over 30 Days</div >
                    </div>
                    <MyLineChart /> */}
                </div>
            </div>
            <DomainKeyModal isOpen={modalOpen} toggle={toggleModal} domain={selectedDomain} setWasGenerated={setSecretKeyWasGenerated} />
        </>
    );
}

export default PublisherStep2;
