import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.css';
import logoImage from './assets/images/Link-Building-Software.png';
import axios from 'axios';
import { useAuth } from './authService';
import { BASE_API_URL } from '../src/config/constants';
import toast from 'react-hot-toast';

const EmailVerification = () => {
  const {user} = useAuth();

  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(180); // 180 seconds = 3 minutes
  const [formData, setFormData] = useState({
    code: ''
  });

  const handleChange = (e) => {
    const { name,value, code } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (timeLeft <= 0) return;
    
    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData.code);
    try {
      const response = await axios.get(`${BASE_API_URL}api/verificationCode/${user.email}/${formData.code}`);
      if(response.data.valid != true)
        console.error('Code is not valid');
      else {
        toast.success("Verification code is valid");
        setTimeout(() => {
          navigate(`/${user.userType}`)
        }, 2000);
      }

      // Handle success or further actions
    } catch (error) {
      console.error('Error:', error);

      // Handle error
    }
    e.preventDefault();
    
    //navigate('/publisher');
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div className="container1">
      <div className="left-side">
        <div className="content">
          <div className="logo-container">
            <img src={logoImage} alt="AdverLink" />
          </div>
          <h3 style={{fontSize:'2.5rem'}} className="my-5">Confirm your email</h3>
          <h5 style={{color:'gray'}}>A verification code has been sent to your email. Enter the code</h5>
          <form onSubmit={handleSubmit}>
            <div className="form-group my-4 w-50">
              <input
                onChange={handleChange}
                name='code'
                type="text"
                placeholder="Enter code"
                required
              />
            </div>
            <h5 style={{color:'gray'}}>You can resend the code in {formatTime(timeLeft)}</h5>
            <button type="submit" className="btn m-4 w-75 btn-primary">Confirm email</button>
          </form>
          <p>
            Haven't received an email? Please check your spam folder or contact us at register@adverlink.net
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
