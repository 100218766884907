import React, { useState, useEffect } from "react";
import FileInput from "./components/FileInput";

const Popularity = ({ data, updateData }) => {
  const [formData, setFormData] = useState(data);

  const handleFileSelect = (files) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      file: files[0],
    }));
  };

  console.log("Popularity data", data);
  // Update parent component data whenever formData changes
  useEffect(() => {
    updateData(formData);
  }, [formData]);

  return (
    <>
      <form style={{ width: "150%" }}>
        <div className="form-group">
          <label htmlFor="">Number of unique users per month *</label>
          <input
            placeholder="Number of visitors"
            type="text"
            value={formData.uniqueUsers || ""}
            onChange={(e) =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                uniqueUsers: e.target.value,
              }))
            }
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="file">
            Attach files documenting the number of visitors (PDF) *
          </label>
          <FileInput onFileSelect={handleFileSelect} data={data} />
          <small style={{ color: "#8f8f8f" }}>
            Add statistics generated directly from Google Analytics for the last
            three months documenting the number of unique users.
          </small>
        </div>
      </form>
    </>
  );
};

export default Popularity;
