import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faBell, faUser, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../authService";
import {jwtDecode} from "jwt-decode";
import { useCart } from "../CartContext"; // Import your CartContext hook

const getUserName = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.sub; // Assuming 'sub' contains the username
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
};

const getIsActiveFromToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.isActive;
  } catch (error) {
    return 0;
  }
}
const token = localStorage.getItem('token');
const isActive = getIsActiveFromToken(token);
console.log(isActive);

const Navbar = ({ onCartClick }) => {
  const navigate = useNavigate();
  const { userType, setUser, setIsLoggedIn, setToken, setUserType } = useAuth();
  const token = localStorage.getItem('token');
  const userName = getUserName(token);

  const { cartItems } = useCart(); // Use the useCart hook
  const cartItemCount = cartItems ? cartItems.length : 0;
  const getUserTypeFromToken = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        return decodedToken.userType; // Assuming 'userType' is the custom claim you set
    } catch (error) {
        console.error("Invalid token", error);
        return null;
    }
};
  const handleLogout = () => {
    // Instead of navigating, we'll reload the page to reset all states
    window.location.href = '/login';
    setIsLoggedIn(false);
    setToken(null);
    setUserType(null);
    localStorage.removeItem('token');
  };

  const linkStyle = {
    cursor: 'pointer',
    color: 'blue',
    textDecoration: 'underline',
  };
  const handleCartClick = () => {
    navigate('/cart');
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light d-inline-flex justify-content-evenly  d-sm-flex justify-content-sm-between px-4 py-3">
      <a
        className="navbar-brand"
        style={linkStyle}
        onClick={(e) => {
          e.preventDefault();
          console.log(userType);
          if (userType === 'advertiser') navigate("/advertiser");
          else navigate("/publisher");
        }}
      >
        <img
          src={require("../assets/images/Link-Building-Software.png")}
          alt="AdverLink"
          style={{ width: "150px" }}
        />
      </a>
      <div className="d-flex align-items-center">
        <button 
          onClick={handleLogout}
          style={{
            padding: '5px 10px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            fontSize: '14px',
            cursor: 'pointer',
            transition: 'background-color 0.3s'
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
        >
          Logout
        </button>
        <span style={{ marginLeft: '10px', marginRight: '10px' }}>{userName}</span>
        <button
          onClick={onCartClick}
          style={{
            position: 'relative',
            padding: '5px 10px',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
        >
{getUserTypeFromToken(token) == 'advertiser' && <div className="cart-icon" style={{ position: 'relative' }} onClick={handleCartClick}>
        <FontAwesomeIcon
          icon={faShoppingCart}
          size="xl"
          style={{ color: "#8f8f8f" }}
        />
        {cartItemCount > 0 && (
          <span className="badge bg-danger rounded-pill" style={{ position: 'absolute', top: '-5px', right: '-10px' }}>
            {cartItemCount}
          </span>
        )}
      </div>
}
        </button>

<a href="/myaccount" style={{ textDecoration: 'none' }}>
  <FontAwesomeIcon
    icon={faUser}
    size="xl"
    style={{ color: "#8f8f8f", marginLeft: '10px' }}
  />
</a>
        {/* <FontAwesomeIcon
          icon={faBell}
          size="xl"
          style={{ color: "#8f8f8f", marginLeft: '10px' }}
        />
        <FontAwesomeIcon
          icon={faGear}
          size="xl"
          style={{ color: "#8f8f8f", marginLeft: '10px' }}
        /> */}
      </div>
    </nav>
  );
};

export default Navbar;
