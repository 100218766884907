import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './authService'; // Ensure this is your auth service import
import './Register.css';
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';
import toast from 'react-hot-toast';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import PageLayout from './components/PageLayout';

const HowToUseIt = () => {
  return (
    <>
    <PageLayout>
      <div className="container px-3">
     <h2>How to use it</h2>
      </div>
      </PageLayout>
    </>
  );
};

export default HowToUseIt;