import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import { BASE_API_URL } from '../src/config/constants';
import { useAuth } from './authService';

const HiddenUsersPanel = () => {
  const [hiddenUsers, setHiddenUsers] = useState([]);
  const navigate = useNavigate();
  const { user, token } = useAuth();

  useEffect(() => {
    if (!user || user.userType !== 'sysadmin') {
      navigate('/');
      return;
    }

    fetchHiddenUsers();
  }, [user, navigate, token]);

  const fetchHiddenUsers = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}api/User/Hidden`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setHiddenUsers(response.data);
    } catch (error) {
      console.error('Error fetching hidden users:', error);
    }
  };

  const handleUnhideUser = async (userId) => {
    try {
      await axios.post(`${BASE_API_URL}api/User/${userId}/unhide`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchHiddenUsers();
    } catch (error) {
      console.error('Error unhiding user:', error);
    }
  };

  if (user?.userType !== 'sysadmin') {
    return <div>Access Denied</div>;
  }

  return (
    <>
      <Navbar />
      <div className="container px-5">
        <Sidebar />
        <div className="main">
          <h5>Hidden Users</h5>
          <table className="table">
            <thead>
              <tr>
                <th>Email</th>
                <th>User Type</th>
                <th>Creation Date</th>
                <th>Last Modified Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {hiddenUsers.map(user => (
                <tr key={user.userId}>
                  <td>{user.email}</td>
                  <td>{user.userType}</td>
                  <td>{new Date(user.creationDate).toLocaleString()}</td>
                  <td>{new Date(user.lastModifiedDate).toLocaleString()}</td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleUnhideUser(user.userId)}
                    >
                      Unhide
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default HiddenUsersPanel;