// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
    position: relative;
}

input.cursor-pointer {
    cursor: pointer; /* Shows pointer cursor to indicate clickability */
}

.text-muted {
    font-size: 0.875rem; /* Smaller text size */
    display: block;
    margin-top: 0.5rem; /* Adds space above the text */
}

.cursor-pointer {
    cursor: pointer; /* Shows pointer cursor to indicate clickability */
}

.input-container input {
    transition: background-color 0.3s; /* Smooth transition for background color */
}

.input-container input:hover {
    background-color: #f0f0f0; /* Light grey background on hover */
}
`, "",{"version":3,"sources":["webpack://./src/DomainKeyModal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe,EAAE,kDAAkD;AACvE;;AAEA;IACI,mBAAmB,EAAE,sBAAsB;IAC3C,cAAc;IACd,kBAAkB,EAAE,8BAA8B;AACtD;;AAEA;IACI,eAAe,EAAE,kDAAkD;AACvE;;AAEA;IACI,iCAAiC,EAAE,2CAA2C;AAClF;;AAEA;IACI,yBAAyB,EAAE,mCAAmC;AAClE","sourcesContent":[".input-container {\n    position: relative;\n}\n\ninput.cursor-pointer {\n    cursor: pointer; /* Shows pointer cursor to indicate clickability */\n}\n\n.text-muted {\n    font-size: 0.875rem; /* Smaller text size */\n    display: block;\n    margin-top: 0.5rem; /* Adds space above the text */\n}\n\n.cursor-pointer {\n    cursor: pointer; /* Shows pointer cursor to indicate clickability */\n}\n\n.input-container input {\n    transition: background-color 0.3s; /* Smooth transition for background color */\n}\n\n.input-container input:hover {\n    background-color: #f0f0f0; /* Light grey background on hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
