import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

const FileInput = ({ onFileSelect, data }) => {
  const [fileName, setFileName] = useState(data.file ? data.file.name : "");

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFileName(acceptedFiles[0].name);
        onFileSelect(acceptedFiles); // Pass the acceptedFiles array to the parent component
      }
    },
    [onFileSelect]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={`dropzone ${isDragActive ? "active" : ""}`}
      style={dropzoneStyles}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Select File or Drag it here</p>
      )}
      {fileName && (
        <div style={{ marginTop: "10px", color: "#21c442" }}>
          <strong>Selected file:</strong> {fileName}
        </div>
      )}
    </div>
  );
};

const dropzoneStyles = {
  border: "2px dashed #21c442",
  borderRadius: "5px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  color: "rgb(90 161 104)",
};

export default FileInput;
