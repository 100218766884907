// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-toggle-button {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;
    background: #007bff;
    color: #fff;
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .submenu {
    margin-left: 20px;
    margin-top: 10px;
  }
  
  .submenu a {
    display: block;
    margin-bottom: 5px;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }

  .sidebar {
    width: 250px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .sidebar {
    
    flex: 0 0 200px;
    height: 40vh;
    overflow-y: auto; /* Keep sidebar scrollable if needed */

    /* background-color: #fff; */
    width: 250px;
    padding: 20px;
    border: 1px solid rgb(222, 221, 221);
    border-radius: 8px;
    transition: transform 0.3s ease-in-out;
    height: 10px;
  }

  .sidebar a {
    display: block;
    color: #7D7D7D;
    text-decoration: none;
  }`, "",{"version":3,"sources":["webpack://./src/components/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,YAAY;IACZ,sBAAsB;IACtB,aAAa;IACb,uCAAuC;EACzC;;EAEA;;IAEE,eAAe;IACf,YAAY;IACZ,gBAAgB,EAAE,sCAAsC;;IAExD,4BAA4B;IAC5B,YAAY;IACZ,aAAa;IACb,oCAAoC;IACpC,kBAAkB;IAClB,sCAAsC;IACtC,YAAY;EACd;;EAEA;IACE,cAAc;IACd,cAAc;IACd,qBAAqB;EACvB","sourcesContent":[".sidebar-toggle-button {\n    position: fixed;\n    top: 20px;\n    left: 20px;\n    z-index: 1000;\n    background: #007bff;\n    color: #fff;\n    border: none;\n    width: 40px;\n    height: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .submenu {\n    margin-left: 20px;\n    margin-top: 10px;\n  }\n  \n  .submenu a {\n    display: block;\n    margin-bottom: 5px;\n  }\n  \n  a {\n    color: #007bff;\n    text-decoration: none;\n  }\n  \n  a:hover {\n    text-decoration: underline;\n  }\n\n  .sidebar {\n    width: 250px;\n    background-color: #fff;\n    padding: 20px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n\n  .sidebar {\n    \n    flex: 0 0 200px;\n    height: 40vh;\n    overflow-y: auto; /* Keep sidebar scrollable if needed */\n\n    /* background-color: #fff; */\n    width: 250px;\n    padding: 20px;\n    border: 1px solid rgb(222, 221, 221);\n    border-radius: 8px;\n    transition: transform 0.3s ease-in-out;\n    height: 10px;\n  }\n\n  .sidebar a {\n    display: block;\n    color: #7D7D7D;\n    text-decoration: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
