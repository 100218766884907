import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Register.css';
import logoImage from './assets/images/Link-Building-Software.png';
import googleLogo from './assets/images/google-logo.png';
import { useAuth } from './authService';
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';
import { jwtDecode } from 'jwt-decode';

const ChooseUserType = () => {
  
  const token = localStorage.getItem('token');
  const getUserName = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.sub; // Assuming 'sub' contains the username
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };
  //todo:
  const { user, userType, setUser, setIsLoggedIn, setToken, setUserType } = useAuth();

  const [formData, setFormData] = useState({
    userType: '',
  });

  const [messages, setMessages] = useState({
    error: '',
    success: '',
  });

  const { register } = useAuth(); // Ensure you have register method in your auth service
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  const handleLogout = () => {
    // Instead of navigating, we'll reload the page to reset all states
    window.location.href = '/login';
    setIsLoggedIn(false);
    setToken(null);
    setUserType(null);
    localStorage.removeItem('token');
  };
  const handleSubmit = async (e) => {
    console.log(user);
    if (formData.userType == '') {
      setMessages({
        error: 'You need to select user type!',
        success: '',
      });
      e.preventDefault();
      return;
    }
    console.log(formData.userType);
    e.preventDefault();
    try {
      console.log('ok');
      let userType = formData.userType;
      const updateUserTypeResponse = await axios.put(
        `${BASE_API_URL}/api/User/updateUserType`, 
        {
          email: getUserName(token),
          userType: userType
        }, 
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (updateUserTypeResponse.status != 200) {
        setMessages({
          error: 'Error updating user type in db',
          success: '',
        });
        return;
      }
      setUser({'email': user.email, 'userType':userType})
      
      handleLogout();
      if (userType == 'advertiser') navigate('/advertiser');
      if (userType == 'publisher') navigate('/publisher');
        
    } catch (error) {
      console.log(error);
      setMessages({
        error: 'This email address is already in use. Try a different email address or restore your password.',
        success: '',
      });
    }
  };

  return (
    <div className="register-container">
      <div className="left-side ">
        <div className="messages">
          {messages.error && <div className="error-message">{messages.error}</div>}
          {messages.success && <div className="success-message">{messages.success}</div>}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group tooltip-container">
            <div className='d-flex justify-content-between pe-2'>
              <label className='w-50' htmlFor="userType">User type:</label>
              <p class="font-italic">𝒊</p>
            </div>
            <select
              style={{ maxWidth: '100%', marginTop: '5px' }}
              id="userType"
              name="userType"
              value={formData.userType}
              onChange={handleChange}
              // required
            >
              <option value="" disabled>Select user type</option>
              <option value="advertiser">Advertiser - Link buyer</option>
              <option value="publisher">Publisher - Link seller</option>
            </select>

            <span className="tooltiptext">Choose Advertiser if you are looking to buy links, choose Publisher if you are looking to sell links</span>
          </div>
          <button type="submit" className="btn btn-primary">Confirm</button>
          </form>
      </div>
    </div>
  );
};

export default ChooseUserType;
