// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.table-responsive {
  width: 100%;
}

.table {
  width: 100%;
  border-collapse: collapse; /* Ensure borders are properly styled */
}

.table th, .table td {
  text-align: left; /* Ensure text alignment is consistent */
  padding: 8px; /* Adjust padding as needed */
}

.table thead th {
  background-color: #f8f9fa; /* Optional: Add a background color to the header */
  border-bottom: 2px solid #dee2e6; /* Optional: Add a border to the header */
}

.table tbody tr:nth-child(odd) {
  background-color: #f9f9f9; /* Optional: Add a row striping effect */
}`, "",{"version":3,"sources":["webpack://./src/YourOrders.css"],"names":[],"mappings":";AACA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,yBAAyB,EAAE,uCAAuC;AACpE;;AAEA;EACE,gBAAgB,EAAE,wCAAwC;EAC1D,YAAY,EAAE,6BAA6B;AAC7C;;AAEA;EACE,yBAAyB,EAAE,mDAAmD;EAC9E,gCAAgC,EAAE,yCAAyC;AAC7E;;AAEA;EACE,yBAAyB,EAAE,wCAAwC;AACrE","sourcesContent":["\n.table-responsive {\n  width: 100%;\n}\n\n.table {\n  width: 100%;\n  border-collapse: collapse; /* Ensure borders are properly styled */\n}\n\n.table th, .table td {\n  text-align: left; /* Ensure text alignment is consistent */\n  padding: 8px; /* Adjust padding as needed */\n}\n\n.table thead th {\n  background-color: #f8f9fa; /* Optional: Add a background color to the header */\n  border-bottom: 2px solid #dee2e6; /* Optional: Add a border to the header */\n}\n\n.table tbody tr:nth-child(odd) {\n  background-color: #f9f9f9; /* Optional: Add a row striping effect */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
