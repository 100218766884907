import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { BASE_API_URL } from './config/constants';
import { jwtDecode } from 'jwt-decode';

//TODO:
const stripePromise = loadStripe('pk_live_51N3JFnL4S5a1WxAjiPBiwb4ShFOftVd2QS4TPJH9dSqSCLylagT0XFSYbPKCppctTwT4WeXwf2wed4Ya05mVYchL00sGrXx9vV');

const token = localStorage.getItem('token');

//gets email
const getUserName = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.sub; // Assuming 'sub' contains the username
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
};
const Checkout = ({ amount, products }) => {
  const [clientSecret, setClientSecret] = useState('');
  const [subscriptionId, setSubscriptionId] = useState('');

  useEffect(() => {
    const createSubscription = async () => {
      try {
        const response = await fetch(`${BASE_API_URL}api/checkout/create-subscription`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            email: getUserName(token),
            amount: amount.toString(),
            products: products
          }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setClientSecret(data.clientSecret);
        setSubscriptionId(data.subscriptionId);
      } catch (error) {
        console.error('Error creating subscription:', error);
      }
    };

    createSubscription();
  }, [amount, products]);

  if (!clientSecret) {
    return <div>Loading...</div>;
  }

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm 
        clientSecret={clientSecret} 
        subscriptionId={subscriptionId} 
        amount={amount}
        products={products}
      />
    </Elements>
  );
};

export default Checkout;