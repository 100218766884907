import React, { useEffect, useState } from 'react';
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { BASE_API_URL } from '../src/config/constants';

const Advertiser = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        cuvantCheie: '',
        addURL: '',
        exclusivity: false,
        linkType: 'Normal',
        linkType2: 'Normal',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        ///DEPRECATED
        const userId = getUserIdFromToken(token); // Implement this function based on your token structure
        const payload = {
            UserId: userId,
            WordToLinks: [
                {
                    Url: formData.addURL,
                    Keyword: formData.cuvantCheie,
                    Title: formData.LinkType2,
                    Exclusivity: formData.exclusivity,
                    LinkType: formData.linkType,
                }
            ]
        };

        try {
            const response = await fetch(`${BASE_API_URL}api/Order/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload),
            });

            // if (!response.ok) {
            //     throw new Error('Network response was not ok');
            // }

            //const result = await response.json();
            //console.log('Success:', result);
            navigate('/your-orders');
            // Handle success (e.g., display a success message or redirect)
        } catch (error) {
            console.error('Error:', error);
            // Handle error (e.g., display an error message)
        }
    };

    const getUserIdFromToken = (token) => {
        try {
            const decodedToken = jwtDecode(token);
            return decodedToken.nameid; // Adjust based on your token's structure
        } catch (error) {
            console.error("Invalid token", error);
            return null;
        }
    };


    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
        } else {
            const userType = getUserTypeFromToken(token);
            if (userType === 'publisher') {
                navigate(`/${userType}`);
            } else if (userType === 'advertiser') {
                navigate(`/${userType}`);
            }
        }
    }, [navigate]);

    const getUserTypeFromToken = (token) => {
        try {
            const decodedToken = jwtDecode(token);
            return decodedToken.userType; // Assuming 'userType' is the custom claim you set
        } catch (error) {
            console.error("Invalid token", error);
            return null;
        }
    };


    return (
        <>
            <Navbar />
            <div className="container4 d-flex">
                <Sidebar />
                <div className="content-container p-4">
                    <div className="header">
                        <h5>Advertiser</h5>
                    </div>
                    <p>
                        (you will be able to buy links in already existing native articles - the most valuable ones)
                    </p>

                    <div className='main3'>
                        <h3 className='my-4'>Add your keywords</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <h6 htmlFor="cuvantCheie">Cuvant Cheie:</h6>
                                <input
                                    placeholder='Enter your keyword'
                                    type="text"
                                    id="cuvantCheie"
                                    name="cuvantCheie"
                                    value={formData.cuvantCheie}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <h6 htmlFor="addURL">Add your URL:</h6>
                                <input
                                    placeholder='Add your URL'
                                    type="text"
                                    id="addURL"
                                    name="addURL"
                                    value={formData.addURL}
                                    onChange={handleChange}
                                />
                            </div>
                            <div style={{ color: '#8f8f8f' }} className="checkbox-group form-group">
                                <input
                                    style={{ width: '20px' }}
                                    type="checkbox"
                                    id="exclusivity"
                                    name="exclusivity"
                                    checked={formData.exclusivity}
                                    onChange={handleChange}
                                />
                                I want Exclusivity
                            </div>
                            <div className="form-group">
                                <p htmlFor="linkType">Type of link you want:</p>
                                <select
                                    className='w-100'
                                    id="linkType"
                                    name="linkType"
                                    value={formData.linkType}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="Normal">Normal</option>
                                    {/* <option value="Option2">Option2</option>
                                    <option value="Option3">Option3</option>
                                    <option value="Option4">Option4</option> */}
                                </select>
                            </div>
                            <div className="form-group">
                                <p htmlFor="linkType2">Type of link you want:</p>
                                <select
                                    className='w-100'
                                    id="linkType2"
                                    name="linkType2"
                                    value={formData.linkType2}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="Normal">DA of the website</option>
                                    {/* <option value="Option2">Option2</option>
                                    <option value="Option3">Option3</option>
                                    <option value="Option4">Option4</option> */}
                                </select>
                            </div>
                            <div className="form-actions mb-3">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Add your keyword
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Advertiser;
