import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { BASE_API_URL } from '../src/config/constants';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import './KeywordsReports.css';
import PageLayout from './components/PageLayout';

const KeywordsReports = () => {
  const [soldKeywords, setSoldKeywords] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // New state for loading

  useEffect(() => {
    const fetchSoldKeywords = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${BASE_API_URL}api/linksreports/get-keyword-reports-for-advertiser`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setSoldKeywords(response.data);
      } catch (error) {
        console.error("Error fetching sold keywords:", error);
        toast.error("Failed to fetch sold keywords.");
      } finally {
        setIsLoading(false); // Set loading to false after fetching data
      }
    };

    fetchSoldKeywords();
  }, []);

  return (
    <>
      <PageLayout>
      <div className="">
        <div className="">
          <div className="">
            <div className="content">
              <h3 className="my-4">Keywords Reports</h3>
              {isLoading ? (
                <p className='p-911'>Loading...</p> // Show loading only during data fetch
              ) : (
                <>
                  {soldKeywords.length === 0 ? (
                    <p>No keywords reports available.</p>  // Show message if no data
                  ) : (
                    <div className="table-responsive">
                      <table className="table table-striped table-hover">
                        <thead className="thead-dark">
                          <tr>
                            <th>Keyword</th>
                            <th>URL</th>
                            <th>Title</th>
                            <th>Exclusivity</th>
                            <th>Link Type</th>
                            <th>Creation Date</th>
                            <th>TargetUrl</th>
                            <th>Occurrences</th>
                            <th>Publisher Website</th>
                            <th>Domain Authority</th>
                            <th>Monthly Value</th>
                            <th>Active Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {soldKeywords.map((keyword, index) => (
                            <tr key={index}>
                              <td>{keyword.keyword}</td>
                              <td>{keyword.url}</td>
                              <td>{keyword.title}</td>
                              <td>{keyword.exclusivity ? 'Yes' : 'No'}</td>
                              <td>{keyword.typeOfLink}</td>
                              <td>{keyword.dateCreated}</td>
                              <td>
                                <a href={keyword.targetUrl} target="_blank" rel="noopener noreferrer">
                                  {keyword.targetUrl}
                                </a>
                              </td>
                              <td>{keyword.occurences}</td>
                              <td>{keyword.publisherWebsite}</td>
                              <td>{keyword.domainAuthority}</td>
                              <td>${keyword.monthlyValue}</td>
                              <td>{keyword.isActive ? 'Active' : 'Inactive'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      </PageLayout>
    </>
  );
};

export default KeywordsReports;
