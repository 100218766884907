import React, { useEffect, useState } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';
import './CheckoutForm.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './authService';
import { BASE_API_URL } from './config/constants';
import { useCart } from './CartContext';
import { jwtDecode } from 'jwt-decode';

const CheckoutForm = ({ clientSecret, subscriptionId, amount, products }) => {
  const { clearCart } = useCart();
  const { user } = useAuth(); // Use the useAuth hook to get the user
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState(user?.sub || '');
  const [companyDetailsExist, setCompanyDetailsExist] = useState(true);

  //TODO: token management
  const token = localStorage.getItem('token');
  const getUserIdFromToken = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        return decodedToken.nameid; // Adjust based on your token's structure
    } catch (error) {
        console.error("Invalid token", error);
        return null;
    }
};

  const fetchCompanyDetails = async () => {
    try {
      const response = await fetch(`${BASE_API_URL}api/companydetails/${user.nameid}`);
      if (response.ok) {
        setCompanyDetailsExist(true);
      } else if (response.status === 404) {
        setCompanyDetailsExist(false);
      } else {
        throw new Error('Failed to fetch company details');
      }
    } catch (error) {
      console.error('Error fetching company details:', error);
      setMessage('Error checking company details. Please try again.');
      setCompanyDetailsExist(false);
    }
  };

  useEffect(() => {
    fetchCompanyDetails();
  }, [user.nameid]);

  console.log(user);
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!stripe || !elements) {
      return;
    }
  
    setIsProcessing(true);
  
    const userId = getUserIdFromToken(token);
    let orderId;
  
    try {
      // Create the order first
      const payload = {
        UserId: userId,
        WordToLinks: products.map(product => ({
          Keyword: product.keyword,
          Url: product.url,
          Title: product.title,
          Exclusivity: product.exclusivity,
          TypeOfLink: product.typeOfLink,
          PublisherWebsite: product.publisherWebsite,
          MonthlyValue: product.monthlyValue,
          DomainAuthority: product.domainAuthority
        })),
      };
  
      const orderResponse = await fetch(`${BASE_API_URL}api/Order/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
      });
  
      if (!orderResponse.ok) {
        throw new Error('Failed to create order');
      }
  
      const orderData = await orderResponse.json();
      orderId = orderData.id; // Assuming the API returns the created order ID
  
      if(!orderId)
      {
        setMessage('Order id was wrong');
        setIsProcessing(false);
        return;
      }

      // Process the payment
      const cardNumber = elements.getElement(CardNumberElement);
      const cardExpiry = elements.getElement(CardExpiryElement);
      const cardCvc = elements.getElement(CardCvcElement);
  
      if (!cardNumber || !cardExpiry || !cardCvc) {
        setMessage('Card elements not found');
        setIsProcessing(false);
        return;
      }
  
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardNumber,
          billing_details: {
            name: name,
            email: email,
          },
        },
      });
  
      if (error) {
        setMessage(`Payment failed: ${error.message}`);
      } else if (paymentIntent.status === 'succeeded') {
        // Update the order to set IsPaid to true
        const updateResponse = await fetch(`${BASE_API_URL}api/Order/${orderId}/setPaid`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });
  
        if (!updateResponse.ok) {
          throw new Error('Failed to update order payment status');
        }
  
        clearCart();
        setMessage('Order placed and payment successful! Thank you for your purchase.');
        setTimeout(() => navigate('/Advertiser-step1'), 3000);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage(`An error occurred: ${error.message}`);
    }
  
    setIsProcessing(false);
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
    hidePostalCode: true,
  };

  return (
    <div className="checkout-form">
      <h2>Order Summary</h2>
      <div className="order-details">
        <p>Total Amount: ${amount} / Month</p>
        <h3>Products:</h3>
        <ul>
          {products.map((product, index) => (
            <li key={index}>
              {product.keyword} - ${product.monthlyValue} / Month
            </li>
          ))}
        </ul>
      </div>
      <form onSubmit={handleSubmit}>
        <h2>Payment Details</h2>
        <div className="form-row">
          <label htmlFor="name">Name</label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-row">
          <label htmlFor="email">Email</label>
          <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          readOnly={!!user?.sub} // Make the field read-only if user email is available
        />
        </div>
        <div className="form-row">
          <label htmlFor="card-number">Card Number</label>
          <CardNumberElement id="card-number" options={cardElementOptions} />
        </div>
        <div className="form-row">
          <label htmlFor="card-expiry">Expiration Date</label>
          <CardExpiryElement id="card-expiry" options={cardElementOptions} />
        </div>
        <div className="form-row">
          <label htmlFor="card-cvc">CVC</label>
          <CardCvcElement id="card-cvc" options={cardElementOptions} />
        </div>
        {!companyDetailsExist && (
          <div className="error-message">
            You must complete company details first!
          </div>
        )}
        <button className="submit-button" type="submit" disabled={!stripe || isProcessing || !companyDetailsExist}>
          {isProcessing ? 'Processing...' : `Pay $${amount} / Month`}
        </button>
      </form>
      {message && <div className="message">{message}</div>}
    </div>
  );
};

export default CheckoutForm;