import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.css';
import logoImage from './assets/images/Link-Building-Software.png';
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Implement your password reset logic here
    try {
      const response = await axios.post(
        `${BASE_API_URL}api/authentication/reset-password/`,
        { email },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      setMessage('Password reset link has been sent to your email.');
    } catch {
      setMessage('Failed to send password reset link. Please try again.');
    }
  };

  const onBackToLogin = () => {
    navigate('/login');
  };

  return (
    <div className="container1">
      <div className="left-side">
        <div className="content">
          <div className="logo-container">
            <img src={logoImage} alt="AdverLink" />
          </div>
          <h3 style={{fontSize:'2.5rem'}} className="my-5">Forgot Password</h3>
          <h5 style={{color:'gray', marginBottom:"25px"}}>Enter your email to reset your password</h5>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">Send Reset Link</button>
          </form>
          {message && <div className="message">{message}</div>}
          <div className="form-footer">
            <a href="#" onClick={onBackToLogin}>Back to Login</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
