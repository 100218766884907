import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import { BASE_API_URL } from './config/constants';
import toast from 'react-hot-toast';
import { jwtDecode } from 'jwt-decode';
import PageLayout from './components/PageLayout';

const YourComponent = () => {
  const [companyDetails, setCompanyDetails] = useState({
    isCompany: false,
    name: '',
    address: '',
    vatNo: '',
    companyRegistryNo: '',
    bankAccount: '',
    representative: '',
    country: '',
  });

  const [countries, setCountries] = useState([]);

  const getUserTypeFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.userType;
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };

  const getUserIdFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.nameid;
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };

  const token = localStorage.getItem('token');
  const userType = getUserTypeFromToken(token);
  const userId = getUserIdFromToken(token);

  useEffect(() => {
    fetchCompanyDetails();
    fetchCountries();
  }, []);

  const fetchCompanyDetails = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}api/CompanyDetails/${userId}`);
      setCompanyDetails(response.data);
    } catch (error) {
      console.error('Error fetching company details:', error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}api/StaticValues/countries`);
      setCountries(response.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    setCompanyDetails(prevDetails => ({
      ...prevDetails,
      [id]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = companyDetails.isCompany
      ? ['name', 'address', 'vatNo', 'companyRegistryNo', 'bankAccount', 'representative', 'country']
      : ['name', 'address', 'bankAccount', 'country'];
    
    const emptyFields = requiredFields.filter(field => !companyDetails[field]);
    if (emptyFields.length > 0) {
      toast.error('Please fill in all required fields');
      return;
    }

    try {
      await axios.put(`${BASE_API_URL}api/CompanyDetails/${userId}`, companyDetails);
      toast.success('Details saved successfully');
    } catch (error) {
      console.error('Error saving details:', error);
      toast.error('Failed to save details');
    }
  };

  return (
    <>
    <PageLayout>
      <div className="container5" style={{width:'150vh'}}>
        <div className="mt-0 subCont5 pt-0">
          <h1>{userType === 'advertiser' ? 'Advertiser': 'Publisher'}</h1>
          <p>(you will be able to buy links in already existing native articles - the most valuable ones)</p>
          
          <h2>Details</h2>
          <p>Please fill in your details for invoicing and pricing</p>

          <form onSubmit={handleSubmit}>
            <div className="form-check mb-3">
              <input 
                type="checkbox" 
                className="form-check-input" 
                id="isCompany" 
                checked={companyDetails.isCompany} 
                onChange={handleInputChange} 
              />
              <label className="form-check-label" htmlFor="isCompany">
                I am a company (not an individual)
              </label>
            </div>

            <div className="form-group">
              <label htmlFor="name">{companyDetails.isCompany ? 'Company Name:' : 'Full Name:'}</label>
              <input type="text" className="form-control" id="name" value={companyDetails.name} onChange={handleInputChange} placeholder={companyDetails.isCompany ? 'Enter company name' : 'Enter your full name'} />
            </div>
            <div className="form-group">
              <label htmlFor="address">Address:</label>
              <input type="text" className="form-control" id="address" value={companyDetails.address} onChange={handleInputChange} placeholder="Enter address" />
            </div>
            {companyDetails.isCompany && (
              <>
                <div className="form-group">
                  <label htmlFor="vatNo">VAT NO:</label>
                  <input type="text" className="form-control" id="vatNo" value={companyDetails.vatNo} onChange={handleInputChange} placeholder="Enter VAT number" />
                </div>
                <div className="form-group">
                  <label htmlFor="companyRegistryNo">Company Registry No:</label>
                  <input type="text" className="form-control" id="companyRegistryNo" value={companyDetails.companyRegistryNo} onChange={handleInputChange} placeholder="Enter company registry number" />
                </div>
              </>
            )}
            <div className="form-group">
              <label htmlFor="bankAccount">Bank Account:</label>
              <input type="text" className="form-control" id="bankAccount" value={companyDetails.bankAccount} onChange={handleInputChange} placeholder="Enter IBAN" />
            </div>
            {companyDetails.isCompany && (
              <div className="form-group">
                <label htmlFor="representative">Company Representative:</label>
                <input type="text" className="form-control" id="representative" value={companyDetails.representative} onChange={handleInputChange} placeholder="Enter name of the administrator" />
              </div>
            )}
            <div className="form-group">
              <label htmlFor="country">Country:</label>
              <select 
                id="country" 
                className="form-control" 
                value={companyDetails.country || ''} 
                onChange={handleInputChange}
              >
                <option value="" disabled>Select a country</option>
                {countries.map((country, index) => (
                  <option key={index} value={country}>{country}</option>
                ))}
              </select>
            </div>
            <button 
              type="submit" 
              className="btn btn-primary"
              style={{ 
                display: 'block', 
                width: '250px', 
                margin: '20px auto', 
                padding: '10px 10px', 
                fontSize: '0.9em'
              }}
            >
              Save details
            </button>
          </form>
        </div>
      </div>
      </PageLayout>
    </>
  );
};

export default YourComponent;