import React, { useState, useEffect, useRef } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';


const data = [
  { day: 0, revenue: 0 },
  { day: 5, revenue: 1000 },
  { day: 10, revenue: 2000 },
  { day: 15, revenue: 3000 },
  { day: 20, revenue: 4000 },
  { day: 25, revenue: 5000 },
  { day: 30, revenue: 6000 }
];

const MyLineChart = () => {
  const chartContainerRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth * 0.9,
    height: window.innerHeight * 0.5
  });

  const updateDimensions = () => {
    if (chartContainerRef.current) {
      const width = chartContainerRef.current.offsetWidth;
      setDimensions({
        width,
        height: window.innerHeight * 0.5
      });
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return (
    <div className="chart-container" ref={chartContainerRef}>
      <LineChart width={dimensions.width} height={dimensions.height} data={data}>
        <CartesianGrid strokeDasharray="5 5" />
        <XAxis dataKey="day" />
        <YAxis ticks={[0, 1000, 2000, 3000, 4000, 5000, 6000]} />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="revenue" name="Day" stroke="#8884d8" />
      </LineChart>
    </div>
  );
};

export default MyLineChart;
