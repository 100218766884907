import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Advertiserx.css';
import Navbar from './components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons'
import Sidebar from './components/Sidebar';
import { jwtDecode } from 'jwt-decode';
import PageLayout from './components/PageLayout';
const AdvertiserLanding = () => {
  const navigate = useNavigate();

  const navigateToStep1 = () => {
    navigate('/Advertiser-step1');
  };

  const getIsActiveFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.isActive;
    } catch (error) {
      return 0;
    }
  }
  const token = localStorage.getItem('token');
  const isActive = getIsActiveFromToken(token);

  return (
    <>
    <PageLayout>
        <div className="main">

          
          {isActive == "False" &&
        <div class="alert alert-danger" role="alert">
Your account will be approved shortly. Thank you!
</div>
}
         <h5>Advertiser</h5>
          <p>(Thank you for using Adverlink for your Link Building Strategy. Start your link buying today!)</p>
          {/* <div className="rules mb-3">
            <h5>Rules to subscribe your website:</h5>
            <p> <FontAwesomeIcon icon={faSquareCheck} size='xl' style={{color: "#21c442",marginRight:'3px'}} /> Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
            <p> <FontAwesomeIcon icon={faSquareCheck} size='xl' style={{color: "#21c442",marginRight:'3px'}} /> Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
            <p> <FontAwesomeIcon icon={faSquareCheck} size='xl' style={{color: "#21c442",marginRight:'3px'}} /> Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
          </div> */}
          <button className="btn btn-primary w-50" onClick={navigateToStep1}>Start now!</button>
        </div>
        </PageLayout>
    </>

  );
};

export default AdvertiserLanding;
