import React from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import './PageLayout.css'; // Optional: add common styles here

const PageLayout = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className="page-layout">
          <Sidebar />
        <div className="">
          <div className="">  {/*classname was content*/}
            {children} {/* Content of each page will be passed here */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PageLayout;