import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './authService'; // Ensure this is your auth service import
import './Register.css';
import logoImage from './assets/images/Link-Building-Software.png';
import googleLogo from './assets/images/google-logo.png';
import axios from 'axios';
import { BASE_API_URL } from '../src/config/constants';
import toast from 'react-hot-toast';

const Register = () => {
  const { login, googleSignIn } = useAuth();
  const {user} = useAuth();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword : '',
    userType: '',
    news: false,
  });

  const [messages, setMessages] = useState({
    error: '',
    success: '',
  });

  const { register } = useAuth(); // Ensure you have register method in your auth service
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    if (formData.password.length < 8)
    {
      setMessages({
        error: 'Password must have at least 8 characters!',
        success: '',
      });
      e.preventDefault();
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setMessages({error:'Passwords do not match.', success :''});
      e.preventDefault();
      return;
    }

    if (formData.userType == '')
      {
        setMessages({
          error: 'Must select an user type!',
          success: '',
        });
        e.preventDefault();
        return;
      }
    e.preventDefault();
    try {
      console.log('aici');
      const formData1 = {
        email: formData.email,  // Replace with actual email or use dynamic data
        // Add other properties as needed by your VerificationCodeModel
      };
      try {
        setLoading(true);
        await register(formData);
        toast.success('Registration successful');
      } catch (error) {
        setMessages(error.message);
        toast.error(error.message);
        setLoading(false);
        return;
      }
       const response = await axios.post(`${BASE_API_URL}api/verificationCode`, formData1, {
        headers: {
          'Content-Type': 'application/json'  // Ensure content type is set to JSON
        }
      });
      console.log('Response:', response);
       //console.log('Response:', response.data);
       //console.log(response);
      navigate('/emailverify'); // Redirect to home page
    } catch (error) {
      console.log(error);
      return;
      setMessages({
        error: 'This email address is already in use. Try a different email address or restore your password.',
        success: '',
      });
    }
  };

  useEffect(() => {
    const initializeGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id: '1048634306191-k05g3uogid9d6vpp2ltl4pquon2r79cq.apps.googleusercontent.com',
        callback: handleCredentialResponse,
        context: 'signin',
        ux_mode: 'popup',
        login_uri: `${BASE_API_URL}api/authentication/google-signin`,
        auto_prompt: false,
      });

      window.google.accounts.id.renderButton(
        document.getElementById('signInDiv'),
        { theme: 'outline', size: 'large' }
      );
    };

    const handleCredentialResponse = async (response) => {
      console.log('Encoded JWT ID token: ' + response.credential);
      await googleSignIn(response.credential);
    };

    if (window.google && window.google.accounts) {
      initializeGoogleSignIn();
    } else {
      window.addEventListener('load', initializeGoogleSignIn);
    }

    return () => {
      window.removeEventListener('load', initializeGoogleSignIn);
    };
  }, [googleSignIn]);

  return (
    <div className="register-container">
      <div className="left-side ">
        <div className="logo mb-5">
          <img src={logoImage} alt="AdverLink" />
        </div>
        <h2 className='my-4'>Create your account</h2>

        <div className="messages">
          {messages.error && <div className="error-message">{messages.error}</div>}
          {messages.success && <div className="success-message">{messages.success}</div>}
        </div>
        <form onSubmit={handleSubmit}>
      <div className="form-group tooltip-container">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          placeholder="Enter your email"
        />
      </div>
      <div className="form-group tooltip-container">
        <div className="d-flex justify-content-between pe-2">
          <label htmlFor="password">Password:</label>
          <p className="font-italic">𝒊</p>
        </div>
        <input
          style={{ marginTop: '5px' }}
          type="password"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
          placeholder="Enter your password"
        />
        <span className="tooltiptext">
          Secure password tips: More than 8 characters, Both lowercase and uppercase letters, At least one numeric character
        </span>
      </div>
      <div className="form-group">
        <label htmlFor="confirmPassword">Confirm Password:</label>
        <input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          placeholder="Confirm your new password"
          required
        />
      </div>
      <div className="form-group tooltip-container">
        <div className="d-flex justify-content-between pe-2">
          <label className="w-50" htmlFor="userType">User type:</label>
          <p className="font-italic">𝒊</p>
        </div>
        <select
          style={{ maxWidth: '100%', marginTop: '5px' }}
          id="userType"
          name="userType"
          value={formData.userType}
          onChange={handleChange}
        >
          <option value="" disabled>Select user type</option>
          <option value="advertiser">Advertiser - Link buyer</option>
          <option value="publisher">Publisher - Link seller</option>
        </select>
        <span className="tooltiptext">
          Choose Advertiser if you are looking to buy links, choose Publisher if you are looking to sell links
        </span>
      </div>
      <div className="form-group checkbox">
        <input
          type="checkbox"
          id="news"
          name="news"
          checked={formData.news}
          onChange={handleChange}
        />
        <small htmlFor="news">Receive AdverLink news and SEO link building tips</small>
      </div>
      <button type="submit" className="btn btn-primary" disabled={loading}>
        {loading ? 'Loading...' : 'Create account'}
      </button>
    </form>
        <div className="form-footer">
          Already have an account? <a href="/login">Log in</a>
        </div>
        <small className="form-footer">
          By creating your account, you agree to the <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>
        </small>
      </div>
    </div>
  );
};

export default Register;
