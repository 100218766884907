import React from 'react';

const UserDetailsModal = ({ user, onClose }) => {
  return (
    <div className="modal" style={{ display: 'block' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">User Details</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Full Name:</strong> {user.fullName}</p>
            <p><strong>User Type:</strong> {user.userType}</p>
            <p><strong>Country:</strong> {user.country}</p>
            <p><strong>Language:</strong> {user.language}</p>
            <p><strong>Phone:</strong> {user.phone}</p>
            <p><strong>Company:</strong> {user.company}</p>
            <p><strong>Time Zone:</strong> {user.timeZone}</p>
            <p><strong>Type of Website:</strong> {user.typeOfWebsite}</p>
            <p><strong>Allow Indexing:</strong> {user.allowIndexing ? 'Yes' : 'No'}</p>
            <p><strong>News Subscription:</strong> {user.newsSubscription ? 'Yes' : 'No'}</p>
            <p><strong>Verified:</strong> {user.verified ? 'Yes' : 'No'}</p>
            <p><strong>Is Active:</strong> {user.isActive ? 'Yes' : 'No'}</p>
            <p><strong>Creation Date:</strong> {new Date(user.creationDate).toLocaleString()}</p>
            <p><strong>Last Modified Date:</strong> {new Date(user.lastModifiedDate).toLocaleString()}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsModal;